import { useState } from 'react';
import {
  Typography,
  useTheme,
  Box,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from '@mui/material';
import dayjs from 'dayjs';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab';
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from '@mui/lab/TimelineOppositeContent';
import { getNameFromArn } from 'src/shared/utils/common-functions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/configure-store';
import { INPUT_DATE_TIME_FORMAT } from 'src/shared/constants/constants';
import {
  azureTimelineDataMapping,
  gcpTimelineDataMapping,
  oktaTimelineDataMapping,
  timelineDataMapping,
  vpcTimelineDataMapping
} from './helper';

export const TimelineView = ({ timelineData, flex }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);
  const { dataSource } = useSelector((state: RootState) => state.common);

  // useEffect(() => {
  //   return function cleanup() {
  //     dispatch(clearInvestigationState());
  //     dispatch(clearInvestigatedAlertDetailsState());
  //   };
  // }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderComponent = (title, value) => (
    <Box py={2}>
      <Typography variant="body2" color={'text.secondary'}>
        {title}
      </Typography>
      <Typography variant="h6">{value ? value : '-'}</Typography>
    </Box>
  );

  const getTimeLineDataMapping = (data: any) => {
    if (dataSource === 'AWS') {
      return timelineDataMapping(data);
    } else if (dataSource === 'OKTA') {
      return oktaTimelineDataMapping(data);
    } else if (dataSource === 'GCP') {
      return gcpTimelineDataMapping(data);
    } else if (dataSource === 'AZURE') {
      return azureTimelineDataMapping(data);
    } else {
      return vpcTimelineDataMapping(data);
    }
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex
        }
      }}
    >
      {timelineData.map((data, index) => (
        <Accordion
          // expanded={expanded === 'panel1'}
          onChange={handleChange(`panel${index}`)}
          key={index}
          disableGutters={true}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              [`& .MuiAccordionSummary-content`]: {
                margin: 0
              }
            }}
          >
            <TimelineItem key={index} sx={{ width: '100%' }}>
              <TimelineOppositeContent color="text.secondary">
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    {dayjs(data.breez_timestamp, INPUT_DATE_TIME_FORMAT).format(
                      'hh:mm A'
                    )}
                  </Typography>
                  <Typography variant="body2" color={'text.secondary'}>
                    {dayjs(data.breez_timestamp, INPUT_DATE_TIME_FORMAT).format(
                      'MM-DD-YYYY'
                    )}
                  </Typography>
                </Box>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index < timelineData?.length - 1 && (
                  <TimelineConnector sx={{ backgroundColor: '#8B939F' }} />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Box>
                  {dataSource !== 'VPC' ? (
                    <Box>
                      <Typography display={'inline'}>
                        {data?.breez_identity_type === 'machine' &&
                        data?.api_name === 'AssumeRole'
                          ? data?.principal_type
                          : getNameFromArn(data?.principal_arn)}
                      </Typography>
                      <Typography color={'text.secondary'} display={'inline'}>
                        {` has performed `}
                      </Typography>
                      <Typography
                        // color={getOperationChipColor(data?.operation)}
                        display={'inline'}
                      >
                        {data?.operation ? data?.operation : ''}
                        {data?.resource?.toUpperCase() === 'NONE'}
                      </Typography>
                      <Typography color={'text.secondary'} display={'inline'}>
                        &nbsp;({data.api_name})
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography display={'inline'}>
                        {`${data?.instance_id} `}
                      </Typography>
                      <Typography color={'text.secondary'} display={'inline'}>
                        {`has ${
                          data?.flow_direction === 'egress'
                            ? 'sent'
                            : 'received'
                        }`}
                      </Typography>
                      <Typography color={'text.secondary'} display={'inline'}>
                        {` data to port `}
                      </Typography>
                      <Typography display={'inline'}>
                        {`${data?.destination_port}`}
                      </Typography>
                      <Typography color={'text.secondary'} display={'inline'}>
                        {` to `}
                      </Typography>
                      <Typography display={'inline'}>
                        {data?.destination_ip_address}
                      </Typography>
                      <Typography color={'text.secondary'} display={'inline'}>
                        {` IP Address`}
                      </Typography>
                      <Chip
                        sx={{ ml: 1 }}
                        label={data?.action}
                        color="primary"
                        size="small"
                      />
                    </Box>
                  )}

                  {dataSource === 'AWS' && (
                    <>
                      <Typography color={'text.secondary'} display={'inline'}>
                        {data?.resource?.toUpperCase() === 'NONE'
                          ? ' '
                          : ` on `}
                      </Typography>
                      {data?.resource?.toUpperCase() === 'NONE' ? null : (
                        <Typography
                          display={'inline'}
                          pr={1}
                          sx={{ wordBreak: 'break-all' }}
                        >
                          {data.resource}
                        </Typography>
                      )}
                    </>
                  )}
                  {dataSource !== 'VPC' && (
                    <Chip
                      sx={{ ml: 1 }}
                      label={
                        data.event_source?.split('.')[0].toUpperCase() ||
                        (dataSource === 'OKTA'
                          ? data?.resource
                          : data?.resource_type)
                      }
                      color="primary"
                      size="small"
                    />
                  )}
                </Box>
              </TimelineContent>
            </TimelineItem>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
              sx={{
                bgcolor: theme.palette.background.default
              }}
            >
              {getTimeLineDataMapping(data)?.map((d: any) => (
                <Grid
                  key={d?.label}
                  item
                  xs={4}
                  md={d?.has_timeline ? 12 : 4}
                  sm={d?.has_timeline ? 12 : 4}
                  lg={d?.has_timeline ? 12 : 4}
                  sx={{
                    overflow: d?.has_timeline ? 'none' : 'auto',
                    wordWrap: d?.has_timeline ? 'none' : 'break-word'
                  }}
                >
                  {d?.has_timeline ? (
                    <>
                      <Typography variant="body2" color={'text.secondary'}>
                        Role Chain
                      </Typography>
                      {d?.value?.split('->').map((item, index) => (
                        <TimelineItem key={item + index}>
                          <TimelineSeparator>
                            <TimelineDot color="primary" />
                            {index < d?.value?.split('->').length - 1 && (
                              <TimelineConnector
                                className="timeline-connector-dashed-border"
                                sx={{ backgroundColor: 'transparent' }}
                              />
                            )}
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography pb={2}>{item}</Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </>
                  ) : (
                    renderComponent(d?.label, d?.value)
                  )}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Timeline>
  );
};
