import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getDashboardMttrDataAPI,
  getDashboardAlertIdentityDataAPI,
  getDashboardDataAPI,
  getDashboardAnomalousDataAPI
} from '../apis/DashboardApi';

interface DashboardState {
  dashboardData: {};
  dashboardAlertIdentityData: any;
  dashboardMttrData: any;
  dashboardHumanAnomalousData: any;
  dashboardNonHumanAnomalousData: any;
  loadingDashboardData: boolean;
  loadingDashboardAlertData: boolean;
  loadingMttrData: boolean;
  loadingHumanAnomalousData: boolean;
  loadingNonHumanAnomalousData: boolean;
}
const initialState: DashboardState = {
  dashboardData: {},
  dashboardAlertIdentityData: {},
  dashboardMttrData: {},
  dashboardHumanAnomalousData: {},
  dashboardNonHumanAnomalousData: {},
  loadingDashboardData: false,
  loadingDashboardAlertData: false,
  loadingMttrData: false,
  loadingHumanAnomalousData: false,
  loadingNonHumanAnomalousData: false
};

export const getDashbaordData = createAsyncThunk(
  'dashboard/getDashbaordData',
  async () => {
    const response = await getDashboardDataAPI();
    return response;
  }
);

export const getDashbaordAlertIdentityData = createAsyncThunk(
  'dashboard/getDashbaordAlertIdentityData',
  async () => {
    const response = await getDashboardAlertIdentityDataAPI();
    return response;
  }
);

export const getDashboardMttrData = createAsyncThunk(
  'dashboard/getDashboardMttrData',
  async (data: any) => {
    const response = await getDashboardMttrDataAPI(data);
    return response;
  }
);

export const getDashboardHumanAnomalousData = createAsyncThunk(
  'dashboard/getDashboardHumanAnomalousData',
  async (data: any) => {
    const response = await getDashboardAnomalousDataAPI(data);
    return response;
  }
);

export const getDashboardNonHumanAnomalousData = createAsyncThunk(
  'dashboard/getDashboardNonHumanAnomalousData',
  async (data: any) => {
    const response = await getDashboardAnomalousDataAPI(data);
    return response;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearHumanNonHumanIdentityData: (state) => {
      state.dashboardHumanAnomalousData = {} as any;
      state.dashboardNonHumanAnomalousData = {} as any;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDashbaordData.pending, (state) => {
        state.loadingDashboardData = true;
      })
      .addCase(
        getDashbaordData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingDashboardData = false;
          if (action.payload) {
            state.dashboardData = action.payload;
          }
        }
      )
      .addCase(getDashbaordData.rejected, (state) => {
        state.loadingDashboardData = false;
      })
      .addCase(getDashbaordAlertIdentityData.pending, (state) => {
        state.loadingDashboardAlertData = true;
      })
      .addCase(
        getDashbaordAlertIdentityData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingDashboardAlertData = false;
          if (action.payload) {
            state.dashboardAlertIdentityData = action.payload;
          }
        }
      )
      .addCase(getDashbaordAlertIdentityData.rejected, (state) => {
        state.loadingDashboardAlertData = false;
      })
      .addCase(getDashboardMttrData.pending, (state) => {
        state.loadingMttrData = true;
      })
      .addCase(
        getDashboardMttrData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingMttrData = false;
          if (action.payload) {
            state.dashboardMttrData = action.payload;
          }
        }
      )
      .addCase(getDashboardMttrData.rejected, (state) => {
        state.loadingMttrData = false;
      })
      .addCase(getDashboardHumanAnomalousData.pending, (state) => {
        state.loadingHumanAnomalousData = true;
      })
      .addCase(
        getDashboardHumanAnomalousData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingHumanAnomalousData = false;
          if (action.payload) {
            state.dashboardHumanAnomalousData = action.payload;
          }
        }
      )
      .addCase(getDashboardHumanAnomalousData.rejected, (state) => {
        state.loadingHumanAnomalousData = false;
      })
      .addCase(getDashboardNonHumanAnomalousData.pending, (state) => {
        state.loadingNonHumanAnomalousData = true;
      })
      .addCase(
        getDashboardNonHumanAnomalousData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingNonHumanAnomalousData = false;
          if (action.payload) {
            state.dashboardNonHumanAnomalousData = action.payload;
          }
        }
      )
      .addCase(getDashboardNonHumanAnomalousData.rejected, (state) => {
        state.loadingNonHumanAnomalousData = false;
      });
  }
});

export const { clearHumanNonHumanIdentityData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
