import { Config, apiGet, apiPost } from 'src/shared/utils/api-request';
import axios from 'axios';
import { DB_TYPE } from 'src/shared/constants/constants';
export const getCommandHistoryAPI = async () => {
  const config = {} as Config;

  config.custom = {
    setLoading: () => {}
  };
  // const res = await apiGet('./investigation.json', config);
  const res = await axios.get(
    '../../../../json/management/investigation.json',
    config
  );
  return res;
};

export const getChangeManagementAPI = async (data: any) => {
  const res = await apiPost(`runtime/change-management${DB_TYPE}`, data);
  return res;
};
