import { IconButton, Tooltip } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Link } from 'react-router-dom';

const HeaderSettings = () => {
  return (
    <Link to="/settings">
      <Tooltip arrow title="Settings">
        <IconButton color="secondary">
          <SettingsOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Link>
  );
};

export default HeaderSettings;
