import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCommandHistoryAPI, getCommandOutputAPI } from '../apis/ForensicApi';
import dayjs from 'dayjs';
import {
  INPUT_DATE_TIME_FORMAT,
  RAW_DATA_PAGINATION_LIMIT
} from 'src/shared/constants/constants';

interface TransactionState {
  commandHistory: [];
  loadingCommandHistory: boolean;
  commandOutput: [];
  loadingCommandOutput: boolean;
  component: string;
  commandExecuted: string;
  retry: boolean;
  investigationAPIPayload: {};
}
// const initialState: TransactionState = {
const initialState = {
  commandHistory: [],
  loadingCommandHistory: false,
  commandOutput: [],
  loadingCommandOutput: false,
  component: 'history',
  commandExecuted: '',
  retry: false,
  investigationAPIPayload: {}
};

export const getCommandHistory = createAsyncThunk(
  'investigation/getCommandHistory',
  async () => {
    const response = await getCommandHistoryAPI();
    return response.data;
  }
);

export const getCommandOutput = createAsyncThunk(
  'investigation/getCommandOutput',
  async (input: any, { dispatch }) => {
    let { data, retryAttempt } = input;
    const response: any = await getCommandOutputAPI(data);
    if (response?.data?.query_execution_id && retryAttempt <= 5) {
      retryAttempt += 1;
      dispatch(setRetry(true));
      setTimeout(
        () =>
          dispatch(
            getCommandOutput({
              data: {
                query_execution_id: response?.data?.query_execution_id,
                limit: RAW_DATA_PAGINATION_LIMIT
              },
              retryAttempt
            })
          ),
        2 ** retryAttempt * 1000
      );
    } else {
      dispatch(setRetry(false));
      return response?.data?.items ? response?.data : { items: [] };
    }
  }
);

export const forensicSlice = createSlice({
  name: 'investigation',
  initialState,
  reducers: {
    setComponent: (state: any, action: PayloadAction<any>) => {
      state.component = action.payload;
    },
    setCommandExecuted: (state: any, action: PayloadAction<any>) => {
      state.commandExecuted = action.payload;
    },
    clearInvestigationState: (state: any) => {
      state.commandOutput = [];
      state.loadingCommandOutput = false;
      state.investigationAPIPayload = {};
      state.commandExecuted = '';
      state.component = '';
      state.commandHistory = [];
    },
    setRetry: (state, action) => {
      state.retry = action.payload;
    },
    setInvestigationAPIPayload: (state, action) => {
      state.investigationAPIPayload = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCommandHistory.pending, (state) => {
        state.loadingCommandHistory = true;
      })
      .addCase(
        getCommandHistory.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingCommandHistory = false;
          if (action.payload) {
            state.commandHistory = [
              ...action.payload.commandHistory,
              ...state.commandHistory
            ];
          }
        }
      )
      .addCase(getCommandHistory.rejected, (state) => {
        state.loadingCommandHistory = false;
      });
    builder
      .addCase(getCommandOutput.pending, (state) => {
        state.loadingCommandOutput = true;
      })
      .addCase(
        getCommandOutput.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingCommandOutput = false;
          if (action.payload) {
            state.commandOutput = setRawData(action, state, 'commandOutput');
          }
        }
      )
      .addCase(getCommandOutput.rejected, (state) => {
        state.loadingCommandOutput = false;
      });
  }
});

const setRawData = (action, state, rawDataStateKey) => {
  const prevState = state?.[rawDataStateKey]?.['data'];

  const sortedData = sortRawData(
    prevState
      ? [...prevState, ...action?.payload?.items]
      : action?.payload?.items
  );
  return {
    data: sortedData,
    lastEvaluatedKey: action?.payload?.last_evaluated_key,
    count: sortedData?.count,
    totalCount: action?.payload?.total_count
  };
};

const sortRawData = (dataList) => {
  let sortedData = dataList?.sort(function (a, b) {
    return (
      dayjs(b.breez_timestamp, INPUT_DATE_TIME_FORMAT).date() -
      dayjs(a.breez_timestamp, INPUT_DATE_TIME_FORMAT).date()
    );
  });
  sortedData = sortedData?.map((d) => {
    if (d.breez_identity_type === 'machine') {
      return {
        ...d,
        principal_type: d?.principal_arn,
        principal_arn: d?.session_name
      };
    } else {
      return d;
    }
  });
  return sortedData;
};

export const {
  setComponent,
  setCommandExecuted,
  clearInvestigationState,
  setRetry,
  setInvestigationAPIPayload
} = forensicSlice.actions;

export default forensicSlice.reducer;
