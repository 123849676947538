import { useState, useEffect } from 'react';
import { ButtonGroup as MuiButtonGroup, useTheme, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconOnly: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: 0
    }
  }
});

export const ButtonGroup = ({ buttons }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState('');
  const [buttonsState, setButtonsState] = useState([...buttons]);

  useEffect(() => {
    setButtonsState(
      buttonsState.map((btn) => {
        if (btn.selected) {
          btn['backgroundColor'] = theme.colors.primary.main;
          btn['color'] = '#fff';
        } else {
          btn['backgroundColor'] = '';
        }
        return btn;
      })
    );
  }, []);

  const handleButtonClick = (propFuntion, param, i) => {
    propFuntion(param);
    setButtonBackgroundColor(theme.colors.primary.main);
    setButtonsState(
      buttonsState.map((btn, index) => {
        if (index === i) {
          btn['backgroundColor'] = theme.colors.primary.main;
          btn['color'] = '#fff';
        } else {
          btn['backgroundColor'] = '';
          btn['color'] = '';
        }
        return btn;
      })
    );
  };
  return (
    <MuiButtonGroup variant="outlined" size="small" color="secondary">
      {buttonsState.map((btn, index) => (
        <Button
          key={index}
          onClick={() => handleButtonClick(btn.function, btn.param, index)}
          sx={{ backgroundColor: btn.backgroundColor, color: btn.color }}
        >
          {btn.text}
        </Button>
      ))}
    </MuiButtonGroup>
  );
};
