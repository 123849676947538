import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  INPUT_DATE_TIME_FORMAT,
  RAW_DATA_PAGINATION_LIMIT
} from 'src/shared/constants/constants';
import { getChangeManagementOutputAPI } from '../apis/investigation';

const initialState = {
  changeManagementOutput: [] as any[],
  changeManagementAPIPayload: {},
  loadingChangeManagement: false,
  retry: false
};

export const getChangeManagementOutput = createAsyncThunk(
  'changeManagementV2/getChangeManagementOutput',
  async (input: any, { dispatch }) => {
    let { data, retryAttempt } = input;
    const response = await getChangeManagementOutputAPI(data);

    if (response?.data?.query_execution_id && retryAttempt <= 5) {
      retryAttempt += 1;
      dispatch(setRetry(true));
      setTimeout(
        () =>
          dispatch(
            getChangeManagementOutput({
              data: {
                query_execution_id: response?.data?.query_execution_id,
                limit: RAW_DATA_PAGINATION_LIMIT
              },
              retryAttempt
            })
          ),
        2 ** retryAttempt * 1000
      );
    } else {
      dispatch(setRetry(false));
      return response?.data?.items ? response?.data : { items: [] };
    }
  }
);

export const changeManagementSlice = createSlice({
  name: 'changeManagementV2',
  initialState,
  reducers: {
    clearChangeManagementState: (state: any) => {
      state.changeManagementOutput = [];
      state.loadingChangeManagement = false;
      state.changeManagementAPIPayload = {};
      state.retry = false;
    },
    setRetry: (state, action) => {
      state.retry = action.payload;
    },
    setChangeManagementAPIPayload: (state, action) => {
      state.changeManagementAPIPayload = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getChangeManagementOutput.pending, (state) => {
        state.loadingChangeManagement = true;
      })
      .addCase(
        getChangeManagementOutput.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingChangeManagement = false;
          if (action.payload) {
            if (action.payload) {
              state.changeManagementOutput = setRawData(
                action,
                state,
                'changeManagementOutput'
              );
            }
          }
        }
      )
      .addCase(getChangeManagementOutput.rejected, (state) => {
        state.loadingChangeManagement = false;
      });
  }
});

const setRawData = (action, state, rawDataStateKey) => {
  const prevState = state?.[rawDataStateKey]?.['data'];

  const sortedData = sortRawData(
    prevState
      ? [...prevState, ...action?.payload?.items]
      : action?.payload?.items
  );
  return {
    data: sortedData,
    lastEvaluatedKey: action?.payload?.last_evaluated_key,
    count: sortedData?.count,
    totalCount: action?.payload?.total_count
  };
};

const sortRawData = (dataList) => {
  let sortedData = dataList?.sort(function (a, b) {
    return (
      dayjs(b.breez_timestamp, INPUT_DATE_TIME_FORMAT).date() -
      dayjs(a.breez_timestamp, INPUT_DATE_TIME_FORMAT).date()
    );
  });
  sortedData = sortedData?.map((d) => {
    if (d.breez_identity_type === 'machine') {
      return {
        ...d,
        principal_type: d?.principal_arn,
        principal_arn: d?.session_name
      };
    } else {
      return d;
    }
  });
  return sortedData;
};
export const {
  clearChangeManagementState,
  setRetry,
  setChangeManagementAPIPayload
} = changeManagementSlice.actions;

export default changeManagementSlice.reducer;
