import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/configure-store';

export const LoadingComponent = () => {
  const { loadingAPIRequest } = useSelector(
    (state: RootState) => state.loadingAPIRequest
  );
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loadingAPIRequest}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default LoadingComponent;
