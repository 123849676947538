import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getIdentityFingerprintAPI,
  getIdentityRawDataAPI,
  getIdentityUsageAPI
} from '../apis/identity-fingerprint';
import dayjs from 'dayjs';
import { INPUT_DATE_TIME_FORMAT } from 'src/shared/constants/constants';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}
interface TransactionState {
  identityFingerprintData: {};
  planeBasedFingerprintData: {};
  loadingPlaneBasedFingerprintData: boolean;
  loadingIdentityFingerprintData: boolean;
  component: string;
  commandExecuted: string;
  selectedIdentityType: string;
  selectedAccountId: [];
  selectedTime: number;
  selectedServiceType: string;
  selectedResourceId: string;
  selectedIdentityInfo: {};
  selectedRawDataFilterKey: string;
  selectedRawDataFilterValue: string;
  rawData: {};
  loadingRawData: boolean;
  geoLocationRawData: {};
  loadingGeoLocationRawData: boolean;
  showBackButton: boolean;
  drawerData: {};
  filterByAccountID: string;
  identityUsage: {};
  loadingIdentityUsage: boolean;
  openViewUsageDialog: boolean;
  investigatedFilters: any;
  selectedSourceType: string;
  routeFilters: any;
  isSubmitClicked: boolean;
  retryGetIdentityFingerprintData: boolean;
  retryGetPlaneBasedFingerprintData: boolean;
  retryRawData: boolean;
  rawDataAPIPayload: any;
}
const initialState: TransactionState = {
  identityFingerprintData: [],
  planeBasedFingerprintData: {},
  loadingPlaneBasedFingerprintData: false,
  loadingIdentityFingerprintData: false,
  component: 'history',
  commandExecuted: '',
  selectedIdentityType: 'Users',
  selectedAccountId: [],
  selectedTime: 1,
  selectedIdentityInfo: {},
  selectedServiceType: '',
  selectedResourceId: '',
  selectedRawDataFilterKey: '',
  selectedRawDataFilterValue: '',
  rawData: {},
  loadingRawData: false,
  geoLocationRawData: {},
  loadingGeoLocationRawData: false,
  showBackButton: false,
  drawerData: {},
  filterByAccountID: '',
  identityUsage: {},
  loadingIdentityUsage: false,
  openViewUsageDialog: false,
  investigatedFilters: {},
  selectedSourceType: '',
  routeFilters: {},
  isSubmitClicked: false,
  retryGetIdentityFingerprintData: false,
  retryGetPlaneBasedFingerprintData: false,
  retryRawData: false,
  rawDataAPIPayload: {} as any
};

const callGetIdentityFingerprintAPI = async (
  input,
  dispatch,
  dispatchAction,
  setRetry
) => {
  let { data, retryAttempt } = input;
  const response = await getIdentityFingerprintAPI(data);
  if (
    response?.data?.identity_fingerprint_data?.query_execution_id &&
    retryAttempt <= 5
  ) {
    retryAttempt += 1;
    dispatch(setRetry(true));
    setTimeout(
      () =>
        dispatch(
          dispatchAction({
            data: {
              ...data,
              query_execution_id:
                response?.data?.identity_fingerprint_data?.query_execution_id
            },
            retryAttempt
          })
        ),
      2 ** retryAttempt * 1000
    );
  } else {
    dispatch(setRetry(false));
    return response?.data?.identity_fingerprint_data;
  }
};

const callGetRawDataAPI = async (input, dispatch, dispatchAction, setRetry) => {
  let { data, retryAttempt } = input;
  const response = await getIdentityRawDataAPI(data);
  if (response?.data?.query_execution_id && retryAttempt <= 5) {
    retryAttempt += 1;
    dispatch(setRetry(true));
    setTimeout(
      () =>
        dispatch(
          dispatchAction({
            data: {
              ...data,
              query_execution_id: response?.data?.query_execution_id
            },
            retryAttempt
          })
        ),
      2 ** retryAttempt * 1000
    );
  } else {
    dispatch(setRetry(false));
    return response?.data?.items ? response?.data : { items: [] };
  }
};

export const getIdentityFingerprint = createAsyncThunk(
  'identityFingerprintV2/getIdentityFingerprint',
  async (input: any, { dispatch }) => {
    const result = await callGetIdentityFingerprintAPI(
      input,
      dispatch,
      getIdentityFingerprint,
      setRetryGetIdentityFingerprintData
    );
    return result;
  }
);

export const getPlaneBasedIdentityFingerprint = createAsyncThunk(
  'identityFingerprintV2/getPlaneBasedIdentityFingerprint',
  async (input: any, { dispatch }) => {
    const result = await callGetIdentityFingerprintAPI(
      input,
      dispatch,
      getPlaneBasedIdentityFingerprint,
      setRetryGetPlaneBasedFingerprintData
    );
    return result;
  }
);

export const getRawData = createAsyncThunk(
  'identityFingerprintV2/getRawData',
  async (data: any, { dispatch }) => {
    const response = await callGetRawDataAPI(
      data,
      dispatch,
      getRawData,
      setRetryRawData
    );
    return response;
  }
);

export const getGeoLocationRawData = createAsyncThunk(
  'identityFingerprintV2/getGeoLocationRawData',
  async (data: any) => {
    const response = await getIdentityRawDataAPI(data);
    return response?.data;
  }
);

export const getIdentityUsage = createAsyncThunk(
  'identityFingerprintV2/getIdentityUsage',
  async (data: any) => {
    const response = await getIdentityUsageAPI(data);
    return response?.data;
  }
);

export const identityFingerprintV2Slice = createSlice({
  name: 'identityFingerprintV2',
  initialState,
  reducers: {
    setSelectedIdentityType: (state, action) => {
      state.selectedIdentityType = action.payload;
    },
    setSelectedAccountId: (state, action) => {
      state.selectedAccountId = action.payload;
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    setSelectedIdentityInfo: (state, action) => {
      state.selectedIdentityInfo = action.payload;
    },
    setSelectedServiceType: (state, action) => {
      state.selectedServiceType = action.payload;
    },
    setSelectedResourceId: (state, action) => {
      state.selectedResourceId = action.payload;
    },
    setSelectedSourceType: (state, action) => {
      state.selectedSourceType = action.payload;
    },
    clearAllIdentityFingerprintData: (state) => {
      state.identityFingerprintData = [];
      state.loadingIdentityFingerprintData = false;
      state.component = 'history';
      state.commandExecuted = '';
      state.selectedIdentityType = 'Users';
      state.selectedAccountId = [];
      state.selectedTime = 1;
      state.selectedIdentityInfo = {};
      state.selectedServiceType = '';
      state.selectedResourceId = '';
      state.rawData = [];
      state.loadingRawData = false;
      state.geoLocationRawData = [];
      state.loadingGeoLocationRawData = false;
      state.filterByAccountID = '';
      state.investigatedFilters = {};
      state.selectedSourceType = '';
      state.planeBasedFingerprintData = [];
      state.loadingPlaneBasedFingerprintData = false;
      state.isSubmitClicked = false;
      state.rawDataAPIPayload = {};
    },
    clearRawData: (state) => {
      state.rawData = [];
    },
    clearGeoLocationRawData: (state) => {
      state.geoLocationRawData = [];
      state.loadingGeoLocationRawData = false;
    },
    setShowBackButton: (state, action) => {
      state.showBackButton = action.payload;
    },
    setDrawerData: (state, action) => {
      state.drawerData = action.payload;
    },
    setFilterByAccountID: (state, action) => {
      state.filterByAccountID = action.payload;
    },
    setOpenViewUsageDialog: (state, action) => {
      state.openViewUsageDialog = action.payload;
    },
    setInvestigatedFilters: (state, action) => {
      state.investigatedFilters = action.payload;
    },
    setRouteFilters: (state, action) => {
      state.routeFilters = action.payload;
    },
    setPlaneBasedFingerprintData: (state, action) => {
      state.planeBasedFingerprintData = action.payload;
    },
    setIsSubmitClicked: (state, action) => {
      state.isSubmitClicked = action.payload;
    },
    setRetryGetIdentityFingerprintData: (state, action) => {
      state.retryGetIdentityFingerprintData = action.payload;
    },
    setRetryGetPlaneBasedFingerprintData: (state, action) => {
      state.retryGetPlaneBasedFingerprintData = action.payload;
    },
    setRetryRawData: (state, action) => {
      state.retryRawData = action.payload;
    },
    setRawDataAPIPayload: (state, action) => {
      state.rawDataAPIPayload = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      // getIdentityFingerprint
      .addCase(getIdentityFingerprint.pending, (state) => {
        state.loadingIdentityFingerprintData = true;
      })
      .addCase(
        getIdentityFingerprint.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingIdentityFingerprintData = false;
          if (action.payload) {
            state.identityFingerprintData = action.payload;
          }
        }
      )
      .addCase(getIdentityFingerprint.rejected, (state) => {
        state.loadingIdentityFingerprintData = false;
      })
      .addCase(getPlaneBasedIdentityFingerprint.pending, (state) => {
        state.loadingPlaneBasedFingerprintData = true;
      })
      .addCase(
        getPlaneBasedIdentityFingerprint.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingPlaneBasedFingerprintData = false;

          if (action.payload) {
            state.planeBasedFingerprintData = action.payload;
          }
        }
      )
      .addCase(getPlaneBasedIdentityFingerprint.rejected, (state) => {
        state.loadingPlaneBasedFingerprintData = false;
      })
      // getRawData
      .addCase(getRawData.pending, (state) => {
        state.loadingRawData = true;
      })
      .addCase(
        getRawData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingRawData = false;
          if (action?.payload) {
            state.rawData = setRawData(action, state, 'rawData');
          }
        }
      )
      .addCase(getRawData.rejected, (state) => {
        state.loadingRawData = false;
      })
      // getGeoLocationRawData
      .addCase(getGeoLocationRawData.pending, (state) => {
        state.loadingGeoLocationRawData = true;
      })
      .addCase(
        getGeoLocationRawData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingGeoLocationRawData = false;
          if (action?.payload) {
            state.geoLocationRawData = setRawData(
              action,
              state,
              'geoLocationRawData'
            );
          }
        }
      )
      .addCase(getGeoLocationRawData.rejected, (state) => {
        state.loadingGeoLocationRawData = false;
      })
      //getIdentityUsage
      .addCase(getIdentityUsage.pending, (state) => {
        state.loadingIdentityUsage = true;
      })
      .addCase(
        getIdentityUsage.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingIdentityUsage = false;
          if (action?.payload) {
            state.identityUsage = action?.payload;
          }
        }
      )
      .addCase(getIdentityUsage.rejected, (state) => {
        state.loadingIdentityUsage = false;
      });
  }
});

const setRawData = (action, state, rawDataStateKey) => {
  const prevState = state?.[rawDataStateKey]?.['data'];

  const sortedData = sortRawData(
    prevState
      ? [...prevState, ...action?.payload?.items]
      : action?.payload?.items
  );
  return {
    data: sortedData,
    lastEvaluatedKey: action?.payload?.last_evaluated_key,
    count: sortedData?.count,
    totalCount: action?.payload?.total_count
  };
};

const sortRawData = (dataList) => {
  let sortedData = dataList?.sort(function (a, b) {
    return (
      dayjs(b.breez_timestamp, INPUT_DATE_TIME_FORMAT).date() -
      dayjs(a.breez_timestamp, INPUT_DATE_TIME_FORMAT).date()
    );
  });
  sortedData = sortedData?.map((d) => {
    if (d.breez_identity_type === 'machine') {
      return {
        ...d,
        principal_type: d?.principal_arn,
        principal_arn: d?.session_name
      };
    } else {
      return d;
    }
  });
  return sortedData;
};
export const {
  setSelectedIdentityType,
  setSelectedAccountId,
  setSelectedTime,
  setSelectedIdentityInfo,
  setSelectedServiceType,
  setSelectedResourceId,
  clearAllIdentityFingerprintData,
  clearRawData,
  clearGeoLocationRawData,
  setShowBackButton,
  setDrawerData,
  setFilterByAccountID,
  setOpenViewUsageDialog,
  setInvestigatedFilters,
  setSelectedSourceType,
  setRouteFilters,
  setPlaneBasedFingerprintData,
  setIsSubmitClicked,
  setRetryGetIdentityFingerprintData,
  setRetryGetPlaneBasedFingerprintData,
  setRetryRawData,
  setRawDataAPIPayload
} = identityFingerprintV2Slice.actions;

export default identityFingerprintV2Slice.reducer;
