import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const getInitials = (text: string) => {
  return text
    ? text
        .split(' ')
        .map((v) => v.slice(0, 1))
        .join('')
        .toUpperCase()
    : '';
};

export const titleCase = (value: string) => {
  if (!value) {
    return '';
  }
  return value.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );
};

export const formatDate = (date: string, format?: string) => {
  const dateFormat = format ? format : 'MM/DD/YYYY';
  return date && dayjs(date).isValid() ? dayjs(date).format(dateFormat) : '';
};

export const formatDateToUtc = (date: string, format?: string) => {
  const dateFormat = format ? format : 'MM/DD/YYYY';
  dayjs.extend(utc);
  return dayjs(date).isValid() ? dayjs(date).utc(true).format(dateFormat) : '';
};

export const removeUnderscore = (text: string) => {
  return text
    ? text.replace(/(?:_| |\b)(\w)/g, function ($1) {
        return $1.toUpperCase().replace('_', ' ');
      })
    : '';
};

export const addUnderscore = (text: string) => {
  return text ? text.replace(/\s+/g, '_')?.toLowerCase() : '';
};
