import { apiPost } from 'src/shared/utils/api-request';

export const getJITRoleListAPI = async (data: any) => {
  const res = await apiPost(`identity/jit-request`, data);
  return res;
};

export const createJITRoleAPI = async (data: any) => {
  const res = await apiPost(`identity/jit-role`, data);
  return res;
};

export const getJITSSORulesAPI = async (data: any) => {
  const res = await apiPost(`client/master-data/jit-sso`, data);
  return res;
};

export const createJITSSORuleAPI = async (data: any) => {
  const res = await apiPost(`client/master-data/jit-sso`, data);
  return res;
};
