import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ConfirmationDialogProps {
  open: boolean;
  content: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onClose: () => void;
  onYes: () => void;
}

export const ConfirmationDialog = ({
  open,
  content,
  primaryButtonText = 'Yes',
  secondaryButtonText = 'No',
  onClose,
  onYes
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" className="flex-basic-start">
        <ErrorOutlineIcon />
        <Box className="text-large-semibold" sx={{ ml: 1 }}>
          Confirmation
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="text-large-regular">{content}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {secondaryButtonText}
        </Button>
        <Button onClick={onYes} autoFocus color="primary">
          {primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
