import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HumanRuntimeData, UserData } from '../models';
import { getCodeIdentityAPI } from '../apis/RuntimeApi';
import { Config } from 'src/shared/utils/api-request';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface TransactionState {
  codeIdentityData: string[];
  loadingCodeIdentityData: boolean;
  codeRuntimeDrawerData: {};
  loadingCodeRuntimeDrawerData: boolean;
  lastEvaluatedKey: LastEvaluatedKey | null;
  crDrawerlastEvaluatedKey: LastEvaluatedKey | null;
  currentPage: number;
  filter: string;
  filterValue: string;
  totalCount: number;
}
const initialState: TransactionState = {
  codeIdentityData: [],
  loadingCodeIdentityData: false,
  codeRuntimeDrawerData: {},
  loadingCodeRuntimeDrawerData: false,
  lastEvaluatedKey: null,
  crDrawerlastEvaluatedKey: null,
  currentPage: 0,
  filter: '',
  filterValue: '',
  totalCount: 0
};

export const getCodeIdentityData = createAsyncThunk(
  'runtime/getCodeIdentityData',
  async (data: any) => {
    const response = await getCodeIdentityAPI(data);
    return response.data;
  }
);

export const getCodeRuntimeDrawerData = createAsyncThunk(
  'runtime/getCodeRuntimeDrawerData',
  async (data: any) => {
    const response = await getCodeIdentityAPI(data);
    return response.data;
  }
);

export const codeIdentitySlice = createSlice({
  name: 'codeIdentity',
  initialState,
  reducers: {
    /*To do - Modify below action as per requirnment */
    addTransaction: (state: any) => {
      state.value += 1;
    },
    deleteTransaction: (state: any) => {
      state.value -= 1;
    },
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setFilter: (state: any, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
    setFilterValue: (state: any, action: PayloadAction<any>) => {
      state.filterValue = action.payload;
    },
    clearData: (state: any) => {
      state.codeIdentityData = [];
      state.codeRuntimeDrawerData = {};
      state.lastEvaluatedKey = null;
      state.crDrawerlastEvaluatedKey = null;
      state.currentPage = 0;
      state.filter = '';
      state.filterValue = '';
      state.totalCount = 0;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCodeIdentityData.pending, (state) => {
        state.loadingCodeIdentityData = true;
      })
      .addCase(
        getCodeIdentityData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingCodeIdentityData = false;

          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d) => {
              return { id: d.breez_event_id, ...d };
            });
            state.codeIdentityData[state.currentPage] = data;
            state.lastEvaluatedKey = action.payload.last_evaluated_key;
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getCodeIdentityData.rejected, (state) => {
        state.loadingCodeIdentityData = false;
      })
      .addCase(getCodeRuntimeDrawerData.pending, (state) => {
        state.loadingCodeRuntimeDrawerData = true;
      })
      .addCase(
        getCodeRuntimeDrawerData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingCodeRuntimeDrawerData = false;

          const selectedFilterKey = state.filter;
          const selectedFilterValue = state.filterValue;
          if (action.payload) {
            const data = action.payload;
            if (
              state.codeRuntimeDrawerData.hasOwnProperty(selectedFilterKey) &&
              state.codeRuntimeDrawerData[selectedFilterKey].hasOwnProperty(
                selectedFilterValue
              )
            ) {
              state.codeRuntimeDrawerData[selectedFilterKey][
                selectedFilterValue
              ] = {
                items: [
                  ...data.items,
                  ...state.codeRuntimeDrawerData[selectedFilterKey][
                    selectedFilterValue
                  ].items
                ],
                lastEvaluatedKey: data.last_evaluated_key,
                totalCount: data.total_count
              };
            } else {
              if (data.items.length) {
                state.codeRuntimeDrawerData = {
                  [selectedFilterKey]: {
                    [selectedFilterValue]: {
                      items: data.items,
                      lastEvaluatedKey: data.last_evaluated_key,
                      totalCount: data.total_count
                    },
                    ...state.codeRuntimeDrawerData[selectedFilterKey]
                  }
                };
              }
            }
          }
        }
      )
      .addCase(getCodeRuntimeDrawerData.rejected, (state) => {
        state.loadingCodeRuntimeDrawerData = false;
      });
  }
});
export const { setCurrentPage, setFilter, setFilterValue, clearData } =
  codeIdentitySlice.actions;
export default codeIdentitySlice.reducer;
