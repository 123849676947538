import { createContext, useContext, useState } from 'react';
import { AuthProviderType } from './models/AuthguardContextType';

export const useAuthProvider = () => {
  const [accessToken, setAccessToken] = useState<string>(
    localStorage.getItem('accessToken') || ''
  );

  const login = (authToken: string) => {
    setAccessToken(authToken);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('logged');
    setAccessToken('');
  };
  return {
    accessToken,
    login,
    logout
  };
};
const defaultAuthContext = {};

const AuthContext = createContext<AuthProviderType>(defaultAuthContext as any);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useAuthProvider();
  return (
    <AuthContext.Provider value={auth as any}>{children}</AuthContext.Provider>
  );
};
