import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveEnquiryAPI } from '../apis/EnquiryApi';
import {
  showErrorMessage,
  showSuccessMessage
} from '../../../shared/components/index';

interface EnquiryState {
  loadingEnquiry: boolean;
}

const initialState: EnquiryState = {
  loadingEnquiry: false
};

export const saveEnquiry = createAsyncThunk(
  'enquiry/saveEnquiry',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await saveEnquiryAPI(data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const enquirySlice = createSlice({
  name: 'enquiry',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(saveEnquiry.pending, (state) => {
        state.loadingEnquiry = true;
      })
      .addCase(
        saveEnquiry.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingEnquiry = false;
          handleResponse(action);
        }
      )
      .addCase(saveEnquiry.rejected, (state, action: any) => {
        state.loadingEnquiry = false;
        showErrorMessage(action.payload?.details);
      });
  }
});

const handleResponse = (action) => {
  if (action.payload) {
    showSuccessMessage(action.payload?.details);
  }
};

export default enquirySlice.reducer;
