import { Navigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthguardContext';

interface IGuardedRouteProps {
  component: any;
  path?: string;
}

export const GuardedRoute = ({
  component: RouteComponent
}: IGuardedRouteProps) => {
  const { accessToken } = useAuth();

  if (accessToken) {
    return <RouteComponent />;
  }
  return <Navigate to="/" />;
};

GuardedRoute.defaultProps = {
  path: ''
};
