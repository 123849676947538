import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';

export const DistributionChart = ({
  handleDataPointSelection,
  handleDistributionChartCenterClick,
  selectedIdentityType
}) => {
  const theme = useTheme();
  const barChartptions: ApexOptions = {
    chart: {
      type: 'donut',
      // offsetY: 20,
      height: 250,
      events: {
        click(e, chart, options) {
          // Perform action when the total value is clicked
          if (
            e.srcElement instanceof SVGElement &&
            e.srcElement.tagName.toLowerCase() === 'circle'
          ) {
            handleDistributionChartCenterClick();
          }
        },
        dataPointSelection: (e, chart, options) => {
          handleDataPointSelection(e, chart, options);
        },
        animationEnd: function (ctx) {
          ctx.toggleDataPointSelection(1);
        }
      }
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 150
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
    stroke: {
      width: 0
    },
    states: {
      active: {
        filter: {
          value: 1,
          type: 'asa'
        }
      }
    },
    colors: ['#3117BC', '#5D45DB', '#A698EB', '#D3CCF5', '#ECE9FF'],
    title: {
      text: ''
    },
    series: [20, 20, 20, 20, 20],
    labels: [
      'API Calls',
      'AWS Services',
      'AWS Roles',
      'Resources',
      'Operations'
    ],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        fontWeight: 500,
        colors: ['#ECE9FF', '#ECE9FF', '#ECE9FF', '#3117BC', '#3117BC']
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.seriesIndex].split(' ');
      },
      dropShadow: {
        enabled: false
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: '40%',
          background: '#281586',

          labels: {
            show: true,
            total: {
              fontSize: '12',
              show: true,
              color: '#fff',
              // label: 'All Identity Fingerprint',
              // label: 'All Activity',
              label: '',

              showAlways: true,
              formatter: function (w) {
                return 'All Activity';
              }
            },
            value: {
              offsetY: -15,
              show: true,
              fontSize: '12',
              color: '#fff'
            }
          }
        }
      }
    },
    legend: {
      show: false
    }
  };
  const [chartOptions, setChartOptions] = useState(barChartptions);

  useEffect(() => {
    let options = { ...chartOptions };
    if (selectedIdentityType === 'Users' || selectedIdentityType === 'Machine')
      options.labels = [
        'API Calls',
        'AWS Services',
        'AWS Roles',
        'Resources',
        'Operations'
      ];
    if (selectedIdentityType === 'Roles')
      options.labels = [
        'API Calls',
        'AWS Services',
        'Identity',
        'Resources',
        'Operations'
      ];

    setChartOptions(options);
  }, [selectedIdentityType]);

  return (
    <Chart
      height={250}
      options={chartOptions}
      series={chartOptions.series}
      type="donut"
    />
  );
};
