import { TextField as MuiTextField } from '@mui/material';
import { TextFieldProps } from './services/Textfieldinterface';
import React from 'react';

export const TextField = React.forwardRef<any, TextFieldProps>(
  ({ ...props }: TextFieldProps, ref: any) => {
    return (
      <MuiTextField
        variant="outlined"
        size="small"
        InputProps={{
          sx: { borderRadius: '4px', padding: '12px' }
        }}
        inputProps={{ style: { padding: 0 } }}
        margin="normal"
        hiddenLabel
        FormHelperTextProps={{ style: { fontWeight: 450 } }}
        required
        fullWidth
        ref={ref}
        {...props}
      />
    );
  }
);
export default TextField;
