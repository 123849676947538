import { FC, ReactNode, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from '../header';
import Sidebar from '../side-bar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/configure-store';
import { getUserAccounts } from 'src/modules/Settings/OrganizationManagement/services/organizationManagement.service';
import {
  getAccessKeys,
  getPlaneCollection,
  getPolicyMasterData,
  getRoles,
  getUsers
} from 'src/modules/common/services/common.services';
import { getIntegrationsDetails } from 'src/modules/SettingsV2/services/settings.service';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const {
    policyMasterData,
    iamUsers,
    iamRoles,
    iamUsersLastEvaluatedKey,
    iamRolesLastEvaluatedKey,
    accessKeys,
    accessKeysLastEvaluatedKey,
    planeCollection
  } = useSelector((state: RootState) => state.common);
  const { userAccounts } = useSelector(
    (state: RootState) => state.organizationManagement
  );
  const { integrationDetails } = useSelector(
    (state: RootState) => state.settings
  );

  //preloading data for performance improvement
  useEffect(() => {
    if (!(policyMasterData && Object.entries(policyMasterData)?.length))
      dispatch(getPolicyMasterData());
    if (!userAccounts?.length) dispatch(getUserAccounts());
    const data = {
      limit: 1000
    };
    if (!iamUsers?.length) dispatch(getUsers(data));
    if (!iamRoles?.length) dispatch(getRoles(data));
    if (!accessKeys?.length) dispatch(getAccessKeys(data));
    if (!planeCollection?.length) dispatch(getPlaneCollection());
    if (!integrationDetails?.length) dispatch(getIntegrationsDetails(data));
  }, []);

  //handling paginated response of iam users
  useEffect(() => {
    if (iamUsersLastEvaluatedKey) {
      const data = {
        limit: 1000,
        last_evaluated_key: iamUsersLastEvaluatedKey
      };
      dispatch(getUsers(data));
    }
  }, [iamUsersLastEvaluatedKey]);

  //handling paginated response of iam roles
  useEffect(() => {
    if (iamRolesLastEvaluatedKey) {
      const data = {
        limit: 1000,
        last_evaluated_key: iamRolesLastEvaluatedKey
      };
      dispatch(getRoles(data));
    }
  }, [iamRolesLastEvaluatedKey]);

  //handling paginated response of iam roles
  useEffect(() => {
    if (accessKeysLastEvaluatedKey) {
      const data = {
        limit: 1000,
        last_evaluated_key: accessKeysLastEvaluatedKey
      };
      dispatch(getAccessKeys(data));
    }
  }, [accessKeysLastEvaluatedKey]);

  return (
    <Box
      className={
        theme.palette.mode === 'dark'
          ? 'layoutbox MuiPageTitlewrapperDark '
          : 'layoutbox MuiPageTitlewrapperLight '
      }
    >
      <Header />
      <Sidebar />
      <Box
        className="layout"
        sx={{
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            ml: `${theme.sidebar.width}`
          }
        }}
      >
        <Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarLayout;
