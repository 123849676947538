import { DB_TYPE } from 'src/shared/constants/constants';
import { apiPost } from 'src/shared/utils/api-request';

export const getInvestigationOutputAPI = async (data: any) => {
  const res = await apiPost('runtime/all-data-source?db_type=athena_v2', data);
  return res;
};

export const getChangeManagementOutputAPI = async (data: any) => {
  const res = await apiPost(`runtime/change-management${DB_TYPE}`, data);
  return res;
};
