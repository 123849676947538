import { apiPost } from 'src/shared/utils/api-request';

export const getIdentityFingerprintAPI = async (data: any) => {
  const res = await apiPost(`identity/fingerprint`, data);
  return res;
};

export const getIdentityRawDataAPI = async (data: any) => {
  const res = await apiPost(`identity/raw-data`, data);
  return res;
};

export const getRolesReportAPI = async (data: any) => {
  const res = await apiPost(`identity/roles/report`, data);
  return res;
};

export const getPermissionSearchResultAPI = async (data: any) => {
  const res = await apiPost(`identity/policy-search`, data);
  return res;
};

export const getIdentityUsageAPI = async (data: any) => {
  const res = await apiPost(`identity/fingerprint/usage`, data);
  return res;
};
