import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HumanRuntimeData, UserData } from '../models';
import { getMachineIdentityAPI } from '../apis/RuntimeApi';
import { Config } from 'src/shared/utils/api-request';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface TransactionState {
  machineIdentityData: string[];
  loadingMachineIdentityData: boolean;
  machineRuntimeDrawerData: {};
  loadingMachineRuntimeDrawerData: boolean;
  lastEvaluatedKey: LastEvaluatedKey | null;
  mrDrawerlastEvaluatedKey: LastEvaluatedKey | null;
  currentPage: number;
  filter: string;
  filterValue: string;
  totalCount: number;
}
const initialState: TransactionState = {
  machineIdentityData: [],
  loadingMachineIdentityData: false,
  machineRuntimeDrawerData: {},
  loadingMachineRuntimeDrawerData: false,
  lastEvaluatedKey: null,
  mrDrawerlastEvaluatedKey: null,
  currentPage: 0,
  filter: '',
  filterValue: '',
  totalCount: 0
};

export const getMachineIdentityData = createAsyncThunk(
  'runtime/getMachineIdentityData',
  async (data: any) => {
    const response = await getMachineIdentityAPI(data);
    return response.data;
  }
);

export const getMachineRuntimeDrawerData = createAsyncThunk(
  'runtime/getMachineRuntimeDrawerData',
  async (data: any) => {
    const response = await getMachineIdentityAPI(data);
    return response.data;
  }
);

export const machineIdentitySlice = createSlice({
  name: 'machineIdentity',
  initialState,
  reducers: {
    /*To do - Modify below action as per requirnment */
    addTransaction: (state: any) => {
      state.value += 1;
    },
    deleteTransaction: (state: any) => {
      state.value -= 1;
    },
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setFilter: (state: any, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
    setFilterValue: (state: any, action: PayloadAction<any>) => {
      state.filterValue = action.payload;
    },
    setActivityData: (state: any, action: PayloadAction<any>) => {
      state.machineRuntimeDrawerData[state.filter] = [action.payload];
    },
    clearData: (state: any) => {
      state.machineIdentityData = [];
      state.machineRuntimeDrawerData = {};
      state.lastEvaluatedKey = null;
      state.mrDrawerlastEvaluatedKey = null;
      state.currentPage = 0;
      state.filter = '';
      state.filterValue = '';
      state.totalCount = 0;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMachineIdentityData.pending, (state) => {
        state.loadingMachineIdentityData = true;
      })
      .addCase(
        getMachineIdentityData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingMachineIdentityData = false;

          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d) => {
              return {
                ...d,
                id: d.breez_event_id
              };
            });
            state.machineIdentityData[state.currentPage] = data;
            state.lastEvaluatedKey = action.payload.last_evaluated_key;
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getMachineIdentityData.rejected, (state) => {
        state.loadingMachineIdentityData = false;
      })
      .addCase(getMachineRuntimeDrawerData.pending, (state) => {
        state.loadingMachineRuntimeDrawerData = true;
      })
      .addCase(
        getMachineRuntimeDrawerData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingMachineRuntimeDrawerData = false;

          const selectedFilterKey = state.filter;
          const selectedFilterValue = state.filterValue;
          if (action.payload) {
            const data = action.payload;
            if (
              state.machineRuntimeDrawerData.hasOwnProperty(
                selectedFilterKey
              ) &&
              state.machineRuntimeDrawerData[selectedFilterKey].hasOwnProperty(
                selectedFilterValue
              )
            ) {
              state.machineRuntimeDrawerData[selectedFilterKey][
                selectedFilterValue
              ] = {
                items: [
                  ...data.items,
                  ...state.machineRuntimeDrawerData[selectedFilterKey][
                    selectedFilterValue
                  ].items
                ],
                lastEvaluatedKey: data.last_evaluated_key,
                totalCount: data.total_count
              };
            } else {
              if (data.items.length) {
                state.machineRuntimeDrawerData = {
                  [selectedFilterKey]: {
                    [selectedFilterValue]: {
                      items: data.items,
                      lastEvaluatedKey: data.last_evaluated_key,
                      totalCount: data.total_count
                    },
                    ...state.machineRuntimeDrawerData[selectedFilterKey]
                  }
                };
              }
            }
          }
        }
      )
      .addCase(getMachineRuntimeDrawerData.rejected, (state) => {
        state.loadingMachineRuntimeDrawerData = false;
      });
  }
});
export const {
  setCurrentPage,
  setFilter,
  setFilterValue,
  setActivityData,
  clearData
} = machineIdentitySlice.actions;
export default machineIdentitySlice.reducer;
