import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createPolicyAPI,
  deletePolicyAPI,
  getAllPoliciesAPI,
  getPolicyMasterDataAPI,
  getRuleCatalogueAPI,
  updatePolicyAPI
} from '../apis/policyApi';
import { showSuccessMessage } from '../../../shared/components/index';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface TransactionState {
  policies: string[];
  ruleCatalogueTableList: any[];
  ruleCatalogueList: any[];
  ruleCatalogueLoading: boolean;
  ruleCatalogueLastEvaKey: LastEvaluatedKey;
  ruleCatalogueCurrentPage: number;
  ruleCatalogueTotalCount: number;
  policyMasterData: {};
  loadingPolicies: boolean;
  loadingPolicyMasterData: boolean;
  currentPage: number;
  totalCount: number;
  lastEvaluatedKey: LastEvaluatedKey;
  ruleCatLastEvaluatedKey: LastEvaluatedKey;
  openPolicyDialog: boolean;
  openCreatePolicyDialog: boolean;
  openDeleteDialog: {
    open: boolean;
    policyId: string;
    ruleName: string;
    rowData: any;
  };
  PolicyDialogData: {};
  policyApiResponse: string;
  ruleCatLoading: boolean;
  ruleCatTotalCount: number;
  ruleCatCurrentPage: number;
  ruleCatalogueDrawerData: any;
  activeRules: string;
  editedSystemRule: any;
  catalogueList: any[];
}
const initialState: TransactionState = {
  policies: [],
  ruleCatalogueTableList: [],
  ruleCatalogueList: [],
  ruleCatalogueLoading: false,
  ruleCatalogueLastEvaKey: null,
  ruleCatalogueCurrentPage: 0,
  ruleCatalogueTotalCount: 0,
  ruleCatLoading: false,
  policyMasterData: {},
  loadingPolicies: false,
  loadingPolicyMasterData: false,
  lastEvaluatedKey: null,
  ruleCatLastEvaluatedKey: null,
  ruleCatTotalCount: 0,
  ruleCatCurrentPage: 0,
  currentPage: 0,
  totalCount: 0,
  openPolicyDialog: false,
  openCreatePolicyDialog: false,
  openDeleteDialog: {
    open: false,
    policyId: '',
    ruleName: '',
    rowData: {} as any
  },
  PolicyDialogData: {},
  policyApiResponse: '',
  ruleCatalogueDrawerData: {},
  activeRules: 'activated',
  editedSystemRule: {} as any,
  catalogueList: [] as any[]
};
export const getAllPolicies = createAsyncThunk(
  'policies/getAllPolicies',
  async (data: any) => {
    const { params } = data;
    const response = await getAllPoliciesAPI(params, data);
    return response.data;
  }
);

export const getRuleCatalogueTableList = createAsyncThunk(
  'policies/getRuleCatalogueTableList',
  async (data: any) => {
    const response = await getRuleCatalogueAPI(data);
    return response.data;
  }
);

export const getRuleCatalogueList = createAsyncThunk(
  'policies/getRuleCatalogueList',
  async (data: any) => {
    const response = await getRuleCatalogueAPI(data);
    return response.data;
  }
);

export const updatePolicy = createAsyncThunk(
  'policies/updatePolicy',
  async (data: any) => {
    const { params, payload } = data;
    const response = await updatePolicyAPI(payload, params);
    return response.data;
  }
);

export const createPolicy = createAsyncThunk(
  'policies/createPolicy',
  async (data: any) => {
    const response = await createPolicyAPI(data);
    return response.data;
  }
);

export const getPolicyMasterData = createAsyncThunk(
  'policies/getPolicyMasterData',
  async () => {
    const response = await getPolicyMasterDataAPI();
    return response.data;
  }
);

export const deletePolicy = createAsyncThunk(
  'policies/deletePolicy',
  async (data: any) => {
    const { policyId, params } = data;
    const response = await deletePolicyAPI(policyId, params);
    return response.data;
  }
);
export const policySlice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setRuleCatCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.ruleCatCurrentPage = action.payload;
    },
    setRuleCatalogueCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.ruleCatalogueCurrentPage = action.payload;
    },
    setPolicies: (state: any, action: PayloadAction<any>) => {
      state.policies[state.currentPage][action.payload.id] = action.payload;
    },
    setOpenPolicyDialog: (state: any, action: PayloadAction<any>) => {
      state.openPolicyDialog = action.payload;
    },
    setOpenCreatePolicyDialog: (state: any, action: PayloadAction<any>) => {
      state.openCreatePolicyDialog = action.payload;
    },
    setOpenDeleteDialog: (state: any, action: PayloadAction<any>) => {
      state.openDeleteDialog = action.payload;
    },
    setRuleCatalogueDrawerData: (state: any, action: PayloadAction<any>) => {
      state.ruleCatalogueDrawerData = action.payload;
    },
    setPolicyDialogData: (state: any, action: PayloadAction<any>) => {
      state.PolicyDialogData = action.payload;
    },
    clearData: (state: any) => {
      state.policies = [];
      state.ruleCatalogueTableList = [];
      state.ruleCatalogueList = [];
      state.lastEvaluatedKey = null;
      state.currentPage = 0;
      state.totalCount = 0;
    },
    setActiveInactive: (state, action) => {
      state.activeRules = action.payload;
    },
    setEditedSystemRule: (state, action) => {
      state.editedSystemRule = action.payload;
    },
    setCatalogueList: (state, action) => {
      state.catalogueList = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllPolicies.pending, (state) => {
        state.loadingPolicies = true;
      })
      .addCase(
        getAllPolicies.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingPolicies = false;

          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d, index) => {
              return { id: index, ...d };
            });
            state.policies = data;
            if (action.payload.last_evaluated_key) {
              state.lastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getAllPolicies.rejected, (state) => {
        state.loadingPolicies = false;
      });

    builder
      .addCase(getRuleCatalogueTableList.pending, (state) => {
        state.ruleCatLoading = true;
      })
      .addCase(
        getRuleCatalogueTableList.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.ruleCatLoading = false;

          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d, index) => {
              return { id: index, ...d };
            });
            state.ruleCatalogueTableList = data;
            if (action.payload.last_evaluated_key) {
              state.ruleCatLastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.ruleCatTotalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getRuleCatalogueTableList.rejected, (state) => {
        state.ruleCatLoading = false;
      });

    builder
      .addCase(getRuleCatalogueList.pending, (state) => {
        state.ruleCatalogueLoading = true;
      })
      .addCase(
        getRuleCatalogueList.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.ruleCatalogueLoading = false;

          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d, index) => {
              return { id: index, ...d };
            });
            state.ruleCatalogueList = data;
            if (action.payload.last_evaluated_key) {
              state.ruleCatalogueLastEvaKey = action.payload.last_evaluated_key;
            }
            state.ruleCatalogueTotalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getRuleCatalogueList.rejected, (state) => {
        state.ruleCatalogueLoading = false;
      });

    builder
      .addCase(createPolicy.pending, (state) => {
        state.loadingPolicies = true;
      })
      .addCase(createPolicy.fulfilled, (state: any, action: any) => {
        state.loadingPolicies = false;
        handleResponse(action, state, 'create');
      })
      .addCase(createPolicy.rejected, (state) => {
        state.loadingPolicies = false;
      });
    builder
      .addCase(updatePolicy.pending, (state) => {
        state.loadingPolicies = true;
      })
      .addCase(updatePolicy.fulfilled, (state: any, action: any) => {
        state.loadingPolicies = false;
        if (action.payload) {
          handleResponse(action, state, 'update');
        }
      })
      .addCase(updatePolicy.rejected, (state) => {
        state.loadingPolicies = false;
      })
      .addCase(getPolicyMasterData.pending, (state) => {
        state.loadingPolicyMasterData = true;
      })
      .addCase(
        getPolicyMasterData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingPolicyMasterData = false;

          if (action.payload) {
            state.policyMasterData = action.payload;
          }
        }
      )
      .addCase(getPolicyMasterData.rejected, (state) => {
        state.loadingPolicyMasterData = false;
      })
      .addCase(deletePolicy.pending, (state) => {
        state.loadingPolicies = true;
      })
      .addCase(deletePolicy.fulfilled, (state: any, action: any) => {
        state.loadingPolicies = false;
        handleResponse(action, state, 'delete');
      })
      .addCase(deletePolicy.rejected, (state) => {
        state.loadingPolicies = false;
      });
  }
});

const handleResponse = (action, state, operationType) => {
  if (action.payload) {
    let data = action.payload;
    state.policyApiResponse =
      JSON.stringify(action.payload) + ' ' + JSON.stringify(action.meta.arg);
    showSuccessMessage(
      operationType === 'update' ? 'Policy updated successfully' : data?.details
    );
  }
};
export const {
  setCurrentPage,
  setPolicies,
  setOpenPolicyDialog,
  setOpenCreatePolicyDialog,
  setPolicyDialogData,
  setOpenDeleteDialog,
  clearData,
  setRuleCatCurrentPage,
  setRuleCatalogueCurrentPage,
  setRuleCatalogueDrawerData,
  setActiveInactive,
  setEditedSystemRule,
  setCatalogueList
} = policySlice.actions;
export default policySlice.reducer;
