import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRolesReportAPI } from '../apis/IdentityFingerprintApi';

interface RolesReportState {
  rolesList: {};
  rolesReportList: [];
  rolesReportFindingList: [];
  loadingRolesList: boolean;
  loadingRolesReportList: boolean;
  loadingRolesReportFindingList: boolean;
}
const initialState: RolesReportState = {
  rolesList: {},
  rolesReportList: [],
  rolesReportFindingList: [],
  loadingRolesList: false,
  loadingRolesReportList: false,
  loadingRolesReportFindingList: false
};

export const getRolesList = createAsyncThunk(
  'rolesReport/getRolesList',
  async (data: any) => {
    const response = await getRolesReportAPI(data);
    return response.data;
  }
);

export const getRolesReportList = createAsyncThunk(
  'rolesReport/getRolesReportList',
  async (data: any) => {
    const response = await getRolesReportAPI(data);
    return response.data;
  }
);

export const getRolesReportFindingList = createAsyncThunk(
  'rolesReport/getRolesReportFindingList',
  async (data: any) => {
    const response = await getRolesReportAPI(data);
    return response.data;
  }
);

export const rolesReportSlice = createSlice({
  name: 'rolesReport',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getRolesList.pending, (state) => {
        state.loadingRolesList = true;
      })
      .addCase(getRolesList.fulfilled, (state: any, action: any) => {
        state.loadingRolesList = false;
        if (action.payload) {
          state.rolesList = action.payload;
        }
      })
      .addCase(getRolesList.rejected, (state) => {
        state.loadingRolesList = false;
      })

      .addCase(getRolesReportList.pending, (state) => {
        state.loadingRolesReportList = true;
      })
      .addCase(getRolesReportList.fulfilled, (state: any, action: any) => {
        state.loadingRolesReportList = false;
        if (action.payload) {
          state.rolesReportList = {
            ...state.rolesReportList,
            [action.meta.arg.sk]: action.payload.items[0]
          };
        }
      })
      .addCase(getRolesReportList.rejected, (state) => {
        state.loadingRolesReportList = false;
      })

      .addCase(getRolesReportFindingList.pending, (state) => {
        state.loadingRolesReportFindingList = true;
      })
      .addCase(
        getRolesReportFindingList.fulfilled,
        (state: any, action: any) => {
          state.loadingRolesReportFindingList = false;
          if (action.payload) {
            state.rolesReportFindingList = {
              ...state.rolesReportFindingList,
              [action.meta.arg.report_id]: action.payload.items[0].finding
            };
          }
        }
      )
      .addCase(getRolesReportFindingList.rejected, (state) => {
        state.loadingRolesReportFindingList = false;
      });
  }
});
export default rolesReportSlice.reducer;
