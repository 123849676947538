import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreezUsersType, CreateUserResponse } from '../models/settings';
import {
  getBreezUsers,
  createBreezUserApi,
  updateBreezUserApi,
  getIntegrationsApi,
  updateIntegrationsApi,
  integrationsDetailApi
} from '../apis/settings';
import {
  showErrorMessage,
  showSuccessMessage
} from 'src/shared/components/toaster/index';

interface TransactionState {
  breezUsers: BreezUsersType[];
  createUserResponse: CreateUserResponse;
  userData: any;
  loading: boolean;
  integrations: any[];
  integrationDetails: any[];
  integrationsPage: number;
  integrationsCount: number;
  loadIntegrationDetails: boolean;
}
const initialState: TransactionState = {
  breezUsers: [] as BreezUsersType[],
  createUserResponse: {} as CreateUserResponse,
  userData: {} as any,
  loading: false,
  integrations: [] as any[],
  integrationDetails: [] as any[],
  integrationsPage: 0,
  integrationsCount: 10,
  loadIntegrationDetails: false
};

export const getBreezUsersList = createAsyncThunk(
  'settings/getBreezUsersList',
  async () => {
    const response = await getBreezUsers();
    return response.data;
  }
);

export const createBreezUser = createAsyncThunk(
  'settings/createBreezUser',
  async (data: any) => {
    const response = await createBreezUserApi(data);
    return response.data;
  }
);

export const updateBreezUser = createAsyncThunk(
  'settings/updateBreezUser',
  async (data: any) => {
    const { email } = data;
    const response = await updateBreezUserApi(email, data);
    return response.data;
  }
);

export const getIntegrations = createAsyncThunk(
  'settings/getIntegrations',
  async () => {
    const response = await getIntegrationsApi();
    return response.data;
  }
);

export const updateIntegrations = createAsyncThunk(
  'settings/updateIntegrations',
  async (data: any) => {
    const response = await updateIntegrationsApi(data);
    return response.data;
  }
);

export const getIntegrationsDetails = createAsyncThunk(
  'settings/getIntegrationsDetails',
  async (data: any) => {
    const response = await integrationsDetailApi(data);
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {},
    setCreateUserResponse: (state, action) => {
      state.createUserResponse = action.payload;
    },
    setLoggedInUserData: (state, action) => {
      state.userData = action.payload;
    },
    setBreezUsersList: (state, action) => {
      state.breezUsers = action.payload;
    },
    setIntegrationsPage: (state, action) => {
      state.integrationsPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBreezUsersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getBreezUsersList.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.breezUsers = action.payload?.users;
          }
        }
      )
      .addCase(getBreezUsersList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createBreezUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createBreezUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.createUserResponse = action.payload;
          }
        }
      )
      .addCase(createBreezUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateBreezUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateBreezUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.breezUsers = action.payload?.users;
          }
        }
      )
      .addCase(updateBreezUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getIntegrations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getIntegrations.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.integrations = action.payload;
          }
        }
      )
      .addCase(getIntegrations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateIntegrations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateIntegrations.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            showSuccessMessage('Successfully updated Integration details');
          }
        }
      )
      .addCase(updateIntegrations.rejected, (state) => {
        state.loading = false;
        showErrorMessage('Error while updating integration');
      })
      .addCase(getIntegrationsDetails.pending, (state) => {
        state.loadIntegrationDetails = true;
      })
      .addCase(
        getIntegrationsDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadIntegrationDetails = false;
          if (action.payload) {
            state.integrationDetails = action.payload?.items;
            state.integrationsCount = action.payload?.total_count;
          }
        }
      )
      .addCase(getIntegrationsDetails.rejected, (state) => {
        state.loadIntegrationDetails = false;
      });
  }
});

export const {
  setCreateUserResponse,
  setLoggedInUserData,
  setBreezUsersList,
  clearResults,
  setIntegrationsPage
} = settingsSlice.actions;
export default settingsSlice.reducer;
