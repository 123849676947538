import { toast, ToastOptions } from 'react-toastify';

const options: ToastOptions = { theme: 'colored' };

export const showSuccessMessage = (message: string) => {
  toast.success(message, options);
};

export const requestPopupMessage = (message: string) => {
  toast.success(message, options);
};

export const showWarningMessage = (message: string) => {
  toast.warn(message, options);
};

export const showErrorMessage = (message: string) => {
  toast.error(message, options);
};
