import * as React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { parseAndFormatDateTime } from 'src/shared/utils/common-functions';
import { removeUnderscore } from 'src/shared/utils/utils';

export function AcknowledgementPopover({
  ackData,
  openAcknowledgementPopover,
  setOpenAcknowledgementPopover,
  ...props
}) {
  //   const [anchorEl, setOpenAcknowledgementPopover] = React.useState<HTMLButtonElement | null>(
  //     null
  //   );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenAcknowledgementPopover(event.currentTarget);
  };

  const handleClose = () => {
    setOpenAcknowledgementPopover(null);
  };

  const open = Boolean(openAcknowledgementPopover);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openAcknowledgementPopover}
      onClose={handleClose}
      className="popover-style"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      {ackData && Object.keys(ackData)?.length > 0 ? (
        <Box sx={{ backgroundColor: '#14181f' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography sx={{ p: 2 }} color={'text.secondary'}>
              Comment:
            </Typography>
            <Typography sx={{ p: 2 }}>{ackData?.comments}</Typography>
          </Box>

          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography sx={{ p: 2 }} color={'text.secondary'}>
              Response:
            </Typography>
            <Typography sx={{ p: 2 }}>
              {removeUnderscore(ackData?.response)}
            </Typography>
          </Box>

          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography sx={{ p: 2 }} color={'text.secondary'}>
              Responded By:
            </Typography>
            <Typography sx={{ p: 2 }}>{ackData?.responded_by}</Typography>
          </Box>

          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography sx={{ p: 2 }} color={'text.secondary'}>
              Time:
            </Typography>
            <Typography sx={{ p: 2 }}>
              {props?.formattedDateTime
                ? ackData?.time
                : parseAndFormatDateTime(ackData?.time)}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          className="flex-basic-center"
          sx={{ backgroundColor: '#14181f', p: 3 }}
        >
          No Comments
        </Box>
      )}
    </Popover>
  );
}
