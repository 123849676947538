import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoaderState {
  loadingDrawerContent: boolean;
  openDrawer: boolean;
}
const initialState: LoaderState = {
  loadingDrawerContent: false,
  openDrawer: false
};

export const APIRequestLoaderSlice = createSlice({
  name: 'ContentDrawer',
  initialState,
  reducers: {
    setLoadingDrawerContent: (state: any, action: PayloadAction<boolean>) => {
      state.loadingDrawerContent = action.payload;
    },
    setOpenDrawer: (state: any, action: PayloadAction<boolean>) => {
      state.openDrawer = action.payload;
    }
  }
});
export const { setLoadingDrawerContent, setOpenDrawer } =
  APIRequestLoaderSlice.actions;
export default APIRequestLoaderSlice.reducer;
