import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createJITRoleAPI,
  createJITSSORuleAPI,
  getJITRoleListAPI,
  getJITSSORulesAPI
} from '../apis/privilegeManagementAPI';
import { showErrorMessage, showSuccessMessage } from 'src/shared/components';

interface LastEvaluatedKey {
  client_id?: string;
  request_id?: string;
}

interface RolesReportState {
  jitSSORules: [];
  loadingJitSSORules: boolean;
  jitRoleDetails: {};
  openCreateJITRoleDialog: boolean;
  lastEvaluatedKey: LastEvaluatedKey | null;
  currentPage: number;
  totalCount: number;
}
const initialState: RolesReportState = {
  jitSSORules: [],
  loadingJitSSORules: false,
  jitRoleDetails: {},
  openCreateJITRoleDialog: false,
  lastEvaluatedKey: null,
  currentPage: 0,
  totalCount: 0
};

export const getJITSSORules = createAsyncThunk(
  'jitSSORule/getJITSSORules',
  async (data: any) => {
    const response = await getJITSSORulesAPI(data);
    return response.data;
  }
);

export const createJITSSORule = createAsyncThunk(
  'jitSSORule/createJITSSORule',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createJITSSORuleAPI(data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const jitSSORuleSlice = createSlice({
  name: 'jitSSORule',
  initialState,
  reducers: {
    setOpenCreateJITRoleDialog: (state: any, action: PayloadAction<any>) => {
      state.openCreateJITRoleDialog = action.payload;
    },
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    clearData: (state: any) => {
      state.humanRuntimeData = [];
      state.humanRuntimeDrawerData = {};
      state.lastEvaluatedKey = null;
      state.currentPage = 0;
      state.totalCount = 0;
    },
    setJitRoleDetails: (state: any, action: PayloadAction<any>) => {
      state.jitRoleDetails = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getJITSSORules.pending, (state) => {
        state.loadingJitSSORules = true;
      })
      .addCase(getJITSSORules.fulfilled, (state: any, action: any) => {
        state.loadingJitSSORules = false;
        if (action.payload) {
          let data = action.payload.items;
          data = data.map((d) => {
            return { id: d.username, ...d };
          });
          state.jitSSORules[state.currentPage] = data;
          state.lastEvaluatedKey = action.payload.last_evaluated_key;
          state.totalCount = action.payload.total_count;
        }
      })
      .addCase(getJITSSORules.rejected, (state) => {
        state.loadingJitSSORules = false;
      })
      .addCase(createJITSSORule.pending, (state) => {
        state.loadingJitSSORules = true;
      })
      .addCase(createJITSSORule.fulfilled, (state: any, action: any) => {
        state.loadingJitSSORules = false;
        if (action.payload) {
          showSuccessMessage(action.payload?.details);
        }
      })
      .addCase(createJITSSORule.rejected, (state, action: any) => {
        state.loadingJitSSORules = false;
        showErrorMessage(action.payload?.details);
      });
  }
});

export const {
  setCurrentPage,
  setOpenCreateJITRoleDialog,
  clearData,
  setJitRoleDetails
} = jitSSORuleSlice.actions;
export default jitSSORuleSlice.reducer;
