import { Box, Typography } from '@mui/material';

interface NoRecordFoundProps {
  Icon?: React.ReactNode;
  mainText?: string;
  subText?: string;
  height?: string | number;
  width?: string | number;
}
export const NoRecordFound = (props: NoRecordFoundProps) => {
  const { Icon, mainText, subText } = props;
  return (
    <Box
      p={2}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: props?.height ? props?.height : '100vh',
        width: props?.width
      }}
    >
      <Box textAlign={'center'}>
        {Icon}
        <Typography variant="h4" fontWeight={600}>
          {mainText ? mainText : 'No Record Found'}
        </Typography>
        <Typography variant="body2" fontSize={12}>
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};
