import { Box } from '@mui/material';
import HeaderSettings from './settings';

const HeaderButtons = () => {
  return (
    <Box sx={{ mr: 1 }} display="flex">
      <HeaderSettings />
    </Box>
  );
};

export default HeaderButtons;
