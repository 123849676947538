import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';

export const DonutChart = ({ seriesData }) => {
  const theme = useTheme();
  const barChartptions: ApexOptions = {
    chart: {
      type: 'donut',
      // offsetY: 20,
      height: '100%'
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 150
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
    stroke: {
      width: 2,
      colors: [theme.palette.background.paper]
    },
    colors: ['#E92F2F', '#F38B11', '#FBC62F', '#5BCE00'],
    title: {
      text: ''
    },
    series: [40, 30, 30],
    labels: ['Critical', 'High', 'Medium', 'Low'],
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      labels: {
        colors: '#fff'
      },
      markers: {
        radius: 2,
        height: 6,
        width: 15
      },
      fontSize: '14',
      position: 'right'
    }
  };
  const [chartOptions, setChartOptions] = useState(barChartptions);

  useEffect(() => {
    setChartOptions({ ...barChartptions, series: seriesData });
  }, [seriesData]);

  return (
    <Chart
      height={240}
      options={chartOptions}
      series={chartOptions?.series}
      type="donut"
    />
  );
};
