import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createJITRoleAPI,
  getJITRoleListAPI,
  getJITSSORulesAPI
} from '../apis/privilegeManagementAPI';
import { showErrorMessage, showSuccessMessage } from 'src/shared/components';

interface LastEvaluatedKey {
  client_id?: string;
  request_id?: string;
}

interface RolesReportState {
  jitRoleList: [];
  SSOUsers: [];
  SSOCloudAccountIds: [];
  SSOServices: [];
  loadingJitSSORules: boolean;
  loadingJITRoleList: boolean;
  jitRoleDetails: {};
  openCreateJITRoleDialog: boolean;
  lastEvaluatedKey: LastEvaluatedKey | null;
  currentPage: number;
  totalCount: number;
}
const initialState: RolesReportState = {
  jitRoleList: [],
  SSOUsers: [],
  SSOCloudAccountIds: [],
  SSOServices: [],
  loadingJitSSORules: false,
  loadingJITRoleList: false,
  jitRoleDetails: {},
  openCreateJITRoleDialog: false,
  lastEvaluatedKey: null,
  currentPage: 0,
  totalCount: 0
};

export const getJITRoleList = createAsyncThunk(
  'jitRole/getJITRoleList',
  async (data: any) => {
    const response = await getJITRoleListAPI(data);
    return response.data;
  }
);

export const getJITSSORules = createAsyncThunk(
  'jitRole/getJITSSORules',
  async (data: any) => {
    const response = await getJITSSORulesAPI(data);
    return response.data;
  }
);

export const createJITRole = createAsyncThunk(
  'jitRole/createJITRole',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createJITRoleAPI(data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const jitRoleSlice = createSlice({
  name: 'jitRole',
  initialState,
  reducers: {
    setOpenCreateJITRoleDialog: (state: any, action: PayloadAction<any>) => {
      state.openCreateJITRoleDialog = action.payload;
    },
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    clearData: (state: any) => {
      state.humanRuntimeData = [];
      state.humanRuntimeDrawerData = {};
      state.lastEvaluatedKey = null;
      state.currentPage = 0;
      state.totalCount = 0;
    },
    setJitRoleDetails: (state: any, action: PayloadAction<any>) => {
      state.jitRoleDetails = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getJITRoleList.pending, (state) => {
        state.loadingJITRoleList = true;
      })
      .addCase(getJITRoleList.fulfilled, (state: any, action: any) => {
        state.loadingJITRoleList = false;
        if (action.payload) {
          let data = action.payload.items;
          data = data.map((d) => {
            return { id: d.request_id, ...d };
          });
          state.jitRoleList = data;
          if (action.payload.last_evaluated_key) {
            state.lastEvaluatedKey = action.payload.last_evaluated_key;
          }
          state.totalCount = action.payload.total_count;
        }
      })
      .addCase(getJITRoleList.rejected, (state) => {
        state.loadingJITRoleList = false;
      })
      .addCase(getJITSSORules.pending, (state) => {
        state.loadingJitSSORules = true;
      })
      .addCase(getJITSSORules.fulfilled, (state: any, action: any) => {
        state.loadingJitSSORules = false;

        if (action.payload) {
          switch (Object.keys(action?.meta?.arg).length) {
            case 1:
              state.SSOUsers = action.payload.items;
              break;
            case 2:
              state.SSOCloudAccountIds = action.payload.items;
              break;
            case 3:
              state.SSOServices = action.payload.items;
              break;
            default:
              break;
          }
        }
      })
      .addCase(getJITSSORules.rejected, (state) => {
        state.loadingJitSSORules = false;
      })
      .addCase(createJITRole.pending, (state) => {
        state.loadingJITRoleList = true;
      })
      .addCase(createJITRole.fulfilled, (state: any, action: any) => {
        state.loadingJITRoleList = false;
        if (action.payload) {
          showSuccessMessage(action.payload?.details);
        }
      })
      .addCase(createJITRole.rejected, (state, action: any) => {
        state.loadingJITRoleList = false;
        showErrorMessage(action.payload?.details);
      });
  }
});

export const {
  setCurrentPage,
  setOpenCreateJITRoleDialog,
  clearData,
  setJitRoleDetails
} = jitRoleSlice.actions;
export default jitRoleSlice.reducer;
