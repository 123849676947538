import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPermissionSearchResultAPI } from '../apis/IdentityFingerprintApi';

interface PermissionSearchResultState {
  permissionSearchResult: {};
  loadingPermissionSearchResult: boolean;
}
const initialState: PermissionSearchResultState = {
  permissionSearchResult: {},
  loadingPermissionSearchResult: false
};

export const getPermissionSearchResult = createAsyncThunk(
  'permissionSearch/getPermissionSearchResult',
  async (data: any) => {
    const response = await getPermissionSearchResultAPI(data);
    return response.data;
  }
);

export const permissionSearchSlice = createSlice({
  name: 'permissionSearch',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getPermissionSearchResult.pending, (state) => {
        state.loadingPermissionSearchResult = true;
      })
      .addCase(
        getPermissionSearchResult.fulfilled,
        (state: any, action: any) => {
          state.loadingPermissionSearchResult = false;
          if (action.payload) {
            state.permissionSearchResult = action.payload;
          }
        }
      )
      .addCase(getPermissionSearchResult.rejected, (state) => {
        state.loadingPermissionSearchResult = false;
      });
  }
});
export default permissionSearchSlice.reducer;
