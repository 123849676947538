import { apiPost } from 'src/shared/utils/api-request';

export const getAlertDetailsAPI = async (data: any) => {
  const alertId = data?.alertId;
  const res = await apiPost(`alerts/${alertId}/details`);
  return res;
};

export const generateCliAPI = async (data: any) => {
  const res = await apiPost('actions/get-cli', data);
  return res;
};

export const remediateAlertAPI = async (alert_id: string, payload: any) => {
  const res = await apiPost(`alerts/${alert_id}/remediate`, payload);
  return res;
};
