import { CSVLink } from 'react-csv';
import { Button } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// const headers = [
//   { label: 'First Name', key: 'firstname' },
//   { label: 'Last Name', key: 'lastname' },
//   { label: 'Email', key: 'email' }
// ];

// const data = [
//   { firstname: 'Ahmed', lastname: 'Tomi', email: 'ah@smthing.co.com' },
//   { firstname: 'Raed', lastname: 'Labes', email: 'rl@smthing.co.com' },
//   { firstname: 'Yezzi', lastname: 'Min l3b', email: 'ymin@cocococo.com' }
// ];

export const CSVDownload = ({ data }) => {
  return (
    <CSVLink
      data={data}
      //   headers={headers}
      filename={'report.csv'}
      style={{ textDecoration: 'none' }}
    >
      <Button
        variant="outlined"
        startIcon={<FileDownloadOutlinedIcon />}
        size="small"
      >
        Download
      </Button>
    </CSVLink>
  );
};
