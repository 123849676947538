import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoaderState {
  loadingAPIRequest: boolean;
}
const initialState: LoaderState = {
  loadingAPIRequest: false
};

export const APIRequestLoaderSlice = createSlice({
  name: 'APIRequestLoader',
  initialState,
  reducers: {
    setLoadingAPIRequest: (state: any, action: PayloadAction<boolean>) => {
      state.loadingAPIRequest = action.payload;
    }
  }
});
export const { setLoadingAPIRequest } = APIRequestLoaderSlice.actions;
export default APIRequestLoaderSlice.reducer;
