import { INTERNAL_AUTH_TOKEN } from 'src/shared/constants/constants';
import { apiPost } from 'src/shared/utils/api-request';

export const saveEnquiryAPI = async (data: any) => {
  const res = await apiPost(`enquiry`, data, {
    headers: {
      Authorization: `Bearer ${INTERNAL_AUTH_TOKEN}`
    }
  });
  return res;
};
