import {
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getAllPoliciesAPI = async (params: any, data: any) => {
  const res = await apiPost('policies', data, { params: params });
  return res;
};

export const getPolicyAPI = async (policy_id: string) => {
  const res = await apiGet(`policies/${policy_id}`);
  return res;
};

export const updatePolicyAPI = async (data: any, params: any) => {
  const policy_id = data.policy_id;
  const res = await apiPut(`policies/${policy_id}`, data, { params: params });
  return res;
};

export const createPolicyAPI = async (data: any) => {
  const policy_id = data.policy_id;
  const res = await apiPost(`policies/create`, data);
  return res;
};

export const getPolicyMasterDataAPI = async () => {
  const res = await apiGet(`policies/master`);
  return res;
};

export const deletePolicyAPI = async (policy_id: string, params: any) => {
  const res = await apiDelete(`policies/${policy_id}`, { params: params });
  return res;
};

export const getRuleCatalogueAPI = async (data: any) => {
  const res = await apiPost('rule-catalogue', data);
  return res;
};

export const updateRuleCatalogueAPI = async (data: any) => {
  const res = await apiPatch('rule-catalogue', data);
  return res;
};

export const activeInactivePolicy = async (policyId: string, payload: any) => {
  const res = await apiPatch(`policies/${policyId}`, payload);
  return res;
};
