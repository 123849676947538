import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getUserProfileAPI,
  updateUserProfileAPI
} from '../apis/ProfileManagement';
import {
  showErrorMessage,
  showSuccessMessage
} from '../../../../shared/components/index';

interface TransactionState {
  userProfile: {};
  loadingUserProfile: boolean;
  updateUserProfileResponse: string;
}
const initialState: TransactionState = {
  userProfile: {},
  loadingUserProfile: false,
  updateUserProfileResponse: ''
};

export const getUserProfile = createAsyncThunk(
  'userProfile/getUserProfile',
  async () => {
    const response = await getUserProfileAPI();
    return response.data;
  }
);

export const updateUserProfile = createAsyncThunk(
  'userProfile/updateUserProfile',
  async (data: any) => {
    const response = await updateUserProfileAPI(data);
    return response.data;
  }
);

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.loadingUserProfile = true;
      })
      .addCase(
        getUserProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingUserProfile = false;

          if (action.payload) {
            state.userProfile = action.payload;
          }
        }
      )
      .addCase(getUserProfile.rejected, (state) => {
        state.loadingUserProfile = false;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loadingUserProfile = true;
      })
      .addCase(
        updateUserProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingUserProfile = false;

          if (action.payload) {
            state.updateUserProfileResponse = action?.payload?.details;
            showSuccessMessage(action?.payload?.details);
          }
        }
      )
      .addCase(updateUserProfile.rejected, (state) => {
        state.loadingUserProfile = false;
        state.updateUserProfileResponse = '';
      });
  }
});

export default userProfileSlice.reducer;
