import { DASHBOARD_API_TIME, DB_TYPE } from 'src/shared/constants/constants';
import { Config, apiGet, apiPost } from 'src/shared/utils/api-request';

export const getDashboardDataAPI = async () => {
  const config = {} as Config;

  config.custom = {
    setLoading: () => {}
  };
  const DASHBOARD_API_URL = !DB_TYPE
    ? `dashboard?time=${DASHBOARD_API_TIME}`
    : `dashboard${DB_TYPE}&time=${DASHBOARD_API_TIME}`;
  const res = await apiGet(DASHBOARD_API_URL, config);
  return res.data;
};

export const getDashboardAlertIdentityDataAPI = async () => {
  const res = await apiGet('dashboard/alerts');
  return res.data;
};

export const getDashboardMttrDataAPI = async (payload: any) => {
  const res = await apiPost('dashboard/alert/alert-mttr', payload);
  return res.data;
};

export const getDashboardAnomalousDataAPI = async (payload: any) => {
  const res = await apiPost('dashboard/alert/anomalous-behaviour', payload);
  return res.data;
};

export const getRolesUsageDataAPI = async (params) => {
  const config = {} as Config;

  config.custom = {
    setLoading: () => {}
  };
  config.params = params;
  const res = await apiGet('identity/roles/active', config);
  return res.data;
};
