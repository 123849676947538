import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HumanRuntimeData, UserData } from '../models';
import { getRolesIdentityAPI } from '../apis/IdentityInventoryAPI';
import { Config } from 'src/shared/utils/api-request';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface RoleIdentityState {
  roleIdentity: HumanRuntimeData[];
  loadingRoleIdentity: boolean;
  roleIdentityDrawerData: {};
  loadingRoleIdentityDrawerData: boolean;
  lastEvaluatedKey: LastEvaluatedKey | null;
  uiDrawerlastEvaluatedKey: LastEvaluatedKey | null;
  currentPage: number;
  filter: string;
  totalCount: number;
}
const initialState: RoleIdentityState = {
  roleIdentity: [],
  loadingRoleIdentity: false,
  roleIdentityDrawerData: {},
  loadingRoleIdentityDrawerData: false,
  lastEvaluatedKey: null,
  uiDrawerlastEvaluatedKey: null,
  currentPage: 0,
  filter: '',
  totalCount: 0
};

export const getRolesIdentity = createAsyncThunk(
  'runtime/getRolesIdentity',
  async (data: any) => {
    const response = await getRolesIdentityAPI(data);
    return response.data;
  }
);

export const getRolesIdentityDrawerData = createAsyncThunk(
  'RoleIdentityInventory/getRolesIdentityDrawerData',
  async (data: any) => {
    const response = await getRolesIdentityAPI(data);
    return response.data;
  }
);

export const RoleIdentityInventorySlice = createSlice({
  name: 'RoleIdentityInventory',
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setFilter: (state: any, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
    setRoleIdentityDrawerData: (state: any, action: PayloadAction<any>) => {
      state.roleIdentityDrawerData = action.payload;
    },
    clearData: (state: any) => {
      state.roleIdentity = [];
      state.roleIdentityDrawerData = {};
      state.lastEvaluatedKey = null;
      state.uiDrawerlastEvaluatedKey = null;
      state.currentPage = 0;
      state.filter = '';
      state.totalCount = 0;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getRolesIdentity.pending, (state) => {
        state.loadingRoleIdentity = true;
      })
      .addCase(
        getRolesIdentity.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingRoleIdentity = false;
          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d) => {
              let dataObj = { id: d.breez_event_id, ...d };
              if (d?.Name) {
                dataObj['RoleName'] = d?.Name;
              }
              dataObj['Arn'] = d?.Arn ? d?.Arn : d?.PermissionSetArn;
              if (d?.cloud_account_id)
                dataObj['account_id'] = d?.cloud_account_id;
              return dataObj;
            });
            state.roleIdentity = data;
            if (action.payload.last_evaluated_key) {
              state.lastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getRolesIdentity.rejected, (state) => {
        state.loadingRoleIdentity = false;
      });
  }
});
export const {
  setCurrentPage,
  setFilter,
  setRoleIdentityDrawerData,
  clearData
} = RoleIdentityInventorySlice.actions;
export default RoleIdentityInventorySlice.reducer;
