import { apiGet, apiPatch } from 'src/shared/utils/api-request';

export const getUserProfileAPI = async () => {
  const res = await apiGet('user/profile');
  return res;
};

export const updateUserProfileAPI = async (data: any) => {
  const res = await apiPatch('user/profile', data);
  return res;
};
