import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserAccountsAPI } from '../apis/OrganizationManagement';

interface TransactionState {
  loadingUserAccounts: boolean;
  userAccounts: [];
}
const initialState: TransactionState = {
  loadingUserAccounts: false,
  userAccounts: []
};

export const getUserAccounts = createAsyncThunk(
  'userAccounts/getUserAccounts',
  async () => {
    const response = await getUserAccountsAPI();
    return response.data;
  }
);

export const userAccountsSlice = createSlice({
  name: 'userAccounts',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getUserAccounts.pending, (state) => {
        state.loadingUserAccounts = true;
      })
      .addCase(
        getUserAccounts.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingUserAccounts = false;

          if (action.payload) {
            state.userAccounts = action?.payload?.items;
          }
        }
      )
      .addCase(
        getUserAccounts.rejected,
        (state, action: PayloadAction<any>) => {
          state.loadingUserAccounts = false;
        }
      );
  }
});

export default userAccountsSlice.reducer;
