import { DB_TYPE } from 'src/shared/constants/constants';
import { Config, apiGet, apiPost } from 'src/shared/utils/api-request';

export const getTransactionDataAPI = async () => {
  const config = {} as Config;

  config.custom = {
    setLoading: () => {}
  };
  const res = await apiPost('management/transaction.json', config);

  return res.data;
};

export const getHumanRuntimeAPI = async (data: any) => {
  const res = await apiPost(`runtime/human${DB_TYPE}`, data);

  return res;
};

export const getCodeIdentityAPI = async (data: any) => {
  const res = await apiPost(`runtime/code${DB_TYPE}`, data);
  return res;
};

export const getMachineIdentityAPI = async (data: any) => {
  const res = await apiPost(`runtime/machine${DB_TYPE}`, data);
  return res;
};
