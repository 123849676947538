import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getActivityLogsApi } from '../apis/activity-log';
import { ActivityLogsResponse } from '../models/activity-log';

interface TransactionState {
  activityLogs: ActivityLogsResponse[];
  loading: boolean;
}
const initialState: TransactionState = {
  activityLogs: [] as ActivityLogsResponse[],
  loading: false
};

export const getActivityLogs = createAsyncThunk(
  'activities/getActivityLogs',
  async (data: object) => {
    const response = await getActivityLogsApi(data);
    return response.data;
  }
);

export const activityLogSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivityLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getActivityLogs.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.activityLogs = action.payload?.activities;
          }
        }
      )
      .addCase(getActivityLogs.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default activityLogSlice.reducer;
