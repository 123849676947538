import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../models';
import { getRolesUsageDataAPI } from '../apis/DashboardApi';

interface DashboardState {
  rolesUsageData: {};
  loadingRolesUsageData: boolean;
}
const initialState: DashboardState = {
  rolesUsageData: {},
  loadingRolesUsageData: false
};
export const getRolesUsageData = createAsyncThunk(
  'rolesUsage/getRolesUsageData',
  async (data: any) => {
    const response = await getRolesUsageDataAPI(data);
    return response;
  }
);
export const dashboardSlice = createSlice({
  name: 'rolesUsage',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getRolesUsageData.pending, (state) => {
        state.loadingRolesUsageData = true;
      })
      .addCase(
        getRolesUsageData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingRolesUsageData = false;

          if (action.payload) {
            state.rolesUsageData = action.payload;
          }
        }
      )
      .addCase(getRolesUsageData.rejected, (state) => {
        state.loadingRolesUsageData = false;
      });
  }
});

export default dashboardSlice.reducer;
