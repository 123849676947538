import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getNonHumanIdentityAPI,
  getPlaneCollectionAPI,
  getPolicyMasterDataAPI,
  getRolesIdentityAPI,
  getUsersIdentityAPI
} from '../apis/common';

interface LastEvaluatedKey {
  pk?: string;
  sk?: string;
}

interface CommonServiceState {
  tab: any;
  loadingPolicyMasterData: boolean;
  dataSource: string;
  policyMasterData: any;
  iamUsers: any[];
  loadingIamUsers: boolean;
  iamUsersLastEvaluatedKey: LastEvaluatedKey | null;
  iamRoles: any[];
  loadingIamRoles: boolean;
  iamRolesLastEvaluatedKey: LastEvaluatedKey | null;
  accessKeys: any[];
  accessKeysLoading: boolean;
  accessKeysLastEvaluatedKey: any;
  planeCollectionLoading: boolean;
  planeCollection: any[];
}
const initialState: CommonServiceState = {
  tab: null,
  loadingPolicyMasterData: false,
  dataSource: 'AWS',
  policyMasterData: {},
  iamUsers: [],
  loadingIamUsers: false,
  iamUsersLastEvaluatedKey: null,
  iamRoles: [],
  loadingIamRoles: false,
  iamRolesLastEvaluatedKey: null,
  accessKeys: [] as any[],
  accessKeysLoading: false,
  accessKeysLastEvaluatedKey: null,
  planeCollectionLoading: false,
  planeCollection: [] as any[]
};

export const getPlaneCollection = createAsyncThunk(
  'common/getPlaneCollection',
  async () => {
    const response = await getPlaneCollectionAPI();
    return response.data;
  }
);

export const getPolicyMasterData = createAsyncThunk(
  'common/getPolicyMasterData',
  async () => {
    const response = await getPolicyMasterDataAPI();
    return response.data;
  }
);

export const getRoles = createAsyncThunk(
  'common/getRoles',
  async (data: any) => {
    const response = await getRolesIdentityAPI(data);
    return response.data;
  }
);

export const getUsers = createAsyncThunk(
  'common/getUsers',
  async (data: any) => {
    const response = await getUsersIdentityAPI(data);
    return response.data;
  }
);

export const getAccessKeys = createAsyncThunk(
  'common/getAccessKeys',
  async (data: any) => {
    const response = await getNonHumanIdentityAPI(data);
    return response.data;
  }
);

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setTab: (state: any, action: PayloadAction<any>) => {
      state.tab = action.payload;
    },
    setDataSource: (state: any, action: PayloadAction<any>) => {
      state.dataSource = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPolicyMasterData.pending, (state) => {
        state.loadingPolicyMasterData = true;
      })
      .addCase(
        getPolicyMasterData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingPolicyMasterData = false;

          if (action.payload) {
            state.policyMasterData = action.payload;
          }
        }
      )
      .addCase(getPolicyMasterData.rejected, (state) => {
        state.loadingPolicyMasterData = false;
      })
      .addCase(getUsers.pending, (state) => {
        state.loadingIamUsers = true;
      })
      .addCase(getUsers.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loadingIamUsers = false;
        if (action.payload) {
          state.iamUsersLastEvaluatedKey = action.payload.last_evaluated_key;
          state.iamUsers = [...state.iamUsers, ...action.payload.items];
        }
      })
      .addCase(getUsers.rejected, (state) => {
        state.loadingIamUsers = false;
      })
      .addCase(getRoles.pending, (state) => {
        state.loadingIamRoles = true;
      })
      .addCase(getRoles.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loadingIamRoles = false;
        if (action.payload) {
          state.iamRolesLastEvaluatedKey = action.payload.last_evaluated_key;
          let data = action.payload.items;
          data = data.map((d) => {
            let dataObj = { id: d.breez_event_id, ...d };
            if (d?.Name) {
              dataObj['RoleName'] = d?.Name;
            }
            return dataObj;
          });
          state.iamRoles = [...state.iamRoles, ...data];
        }
      })
      .addCase(getRoles.rejected, (state) => {
        state.loadingIamRoles = false;
      })
      .addCase(getAccessKeys.pending, (state) => {
        state.accessKeysLoading = true;
      })
      .addCase(
        getAccessKeys.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.accessKeysLoading = false;
          if (action.payload) {
            let data = action.payload.items;
            data = data.map((d, index) => {
              return {
                ...d,
                id: index
              };
            });
            state.accessKeys = data;
            state.accessKeysLastEvaluatedKey =
              action.payload.last_evaluated_key;
          }
        }
      )
      .addCase(getAccessKeys.rejected, (state) => {
        state.accessKeysLoading = false;
      })
      .addCase(getPlaneCollection.pending, (state) => {
        state.planeCollectionLoading = true;
      })
      .addCase(
        getPlaneCollection.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.planeCollectionLoading = false;
          if (action.payload) {
            state.planeCollection = action.payload;
          }
        }
      )
      .addCase(getPlaneCollection.rejected, (state) => {
        state.planeCollectionLoading = false;
      });
  }
});
export const { setTab, setDataSource } = commonSlice.actions;
export default commonSlice.reducer;
