import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changePasswordAPI } from '../apis/ChangePassword';
import {
  showErrorMessage,
  showSuccessMessage
} from '../../../../shared/components/index';

interface TransactionState {
  loadingChangePassword: boolean;
  changePasswordResponse: string;
}
const initialState: TransactionState = {
  loadingChangePassword: false,
  changePasswordResponse: ''
};

export const changePassword = createAsyncThunk(
  'changePassword/changePassword',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await changePasswordAPI(data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loadingChangePassword = true;
      })
      .addCase(
        changePassword.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingChangePassword = false;

          if (action.payload) {
            state.changePasswordResponse = action?.payload?.details;
            showSuccessMessage(action?.payload?.details);
          }
        }
      )
      .addCase(changePassword.rejected, (state, action: PayloadAction<any>) => {
        state.loadingChangePassword = false;
        state.changePasswordResponse = '';
        showErrorMessage(action.payload?.details);
      });
  }
});

export default changePasswordSlice.reducer;
