import { Drawer, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/configure-store';
import { useDispatch } from 'react-redux';
import { setOpenDrawer } from './services/drawer.service';

interface DrawerComponentProps {
  children: React.ReactNode;
  width?: number;
}

export const DrawerComponent = (props: DrawerComponentProps) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const { openDrawer } = useSelector((state: RootState) => state.contentDrawer);

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={() => dispatch(setOpenDrawer(false))}
      PaperProps={{
        sx: {
          width: props?.width ? props?.width : 440
        }
      }}
    >
      {props?.children}
    </Drawer>
  );
};
