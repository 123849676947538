import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAppsIdentityAPI } from '../apis/IdentityInventoryAPI';

interface AppsState {
  appsList: any[];
  loadingApps: boolean;
  appsDrawerData: any;
  lastEvaluatedKey: any;
  currentPage: number;
  totalCount: number;
}
const initialState: AppsState = {
  appsList: [],
  loadingApps: false,
  appsDrawerData: {},
  lastEvaluatedKey: null,
  currentPage: 0,
  totalCount: 0
};

export const getAppsList = createAsyncThunk(
  'apps/getAppsList',
  async (data: any) => {
    const response = await getAppsIdentityAPI(data);
    return response.data;
  }
);

export const appsIdentityInventorySlice = createSlice({
  name: 'appsIdentityInventory',
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setAppsDrawerData: (state: any, action: PayloadAction<any>) => {
      state.appsDrawerData = action.payload;
    },
    clearData: (state: any) => {
      state.appsList = [];
      state.appsDrawerData = {};
      state.lastEvaluatedKey = null;
      state.currentPage = 0;
      state.totalCount = 0;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAppsList.pending, (state) => {
        state.loadingApps = true;
      })
      .addCase(
        getAppsList.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingApps = false;
          if (action.payload) {
            let data = [...action.payload.items];
            const result = data.map((d: any) => {
              return { ...d, id: d?.AppId };
            });
            state.appsList = result;
            if (action.payload.last_evaluated_key) {
              state.lastEvaluatedKey = action.payload.last_evaluated_key;
            }
            state.totalCount = action.payload.total_count;
          }
        }
      )
      .addCase(getAppsList.rejected, (state) => {
        state.loadingApps = false;
      });
  }
});
export const { setCurrentPage, setAppsDrawerData, clearData } =
  appsIdentityInventorySlice.actions;
export default appsIdentityInventorySlice.reducer;
