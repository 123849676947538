import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  generateCliAPI,
  getAlertDetailsAPI,
  remediateAlertAPI
} from '../apis/remediations';
import {
  showErrorMessage,
  showSuccessMessage
} from 'src/shared/components/toaster';

interface RemediationsState {
  alertDetails: any;
  loadingAlertDetails: boolean;
  cliData: any;
  loadingCliData: boolean;
  loadingRemediation: boolean;
  isActionSelected: boolean;
  isRemediationSuccess: boolean;
}
const initialState: RemediationsState = {
  alertDetails: {} as any,
  loadingAlertDetails: false,
  cliData: {} as any,
  loadingCliData: false,
  loadingRemediation: false,
  isActionSelected: false,
  isRemediationSuccess: false
};

export const getAlertDetails = createAsyncThunk(
  'remediations/getAlertDetails',
  async (data: any) => {
    const response = await getAlertDetailsAPI(data);
    return response.data;
  }
);

export const getCLIData = createAsyncThunk(
  'remediations/getCLIData',
  async (data: any) => {
    const response = await generateCliAPI(data);
    return response.data;
  }
);

export const remediateAlert = createAsyncThunk(
  'remediations/remediateAlert',
  async (data: any, { rejectWithValue }) => {
    try {
      const { alert_id, payload } = data;
      const response = await remediateAlertAPI(alert_id, payload);
      showSuccessMessage(response.data?.details);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      showErrorMessage(err.response.data?.details);
      return rejectWithValue(err.response.data);
    }
  }
);

export const remediationsSlice = createSlice({
  name: 'remediations',
  initialState,
  reducers: {
    setIsActionSelected: (state, action) => {
      state.isActionSelected = action.payload;
    },
    clearState: (state) => {
      state.isActionSelected = false;
      state.isRemediationSuccess = false;
      state.alertDetails = {} as any;
      state.cliData = {} as any;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlertDetails.pending, (state) => {
        state.loadingAlertDetails = true;
      })
      .addCase(
        getAlertDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingAlertDetails = false;
          if (action.payload) {
            state.alertDetails = action.payload;
          }
        }
      )
      .addCase(getAlertDetails.rejected, (state) => {
        state.loadingAlertDetails = false;
      })
      .addCase(getCLIData.pending, (state) => {
        state.loadingCliData = true;
      })
      .addCase(
        getCLIData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingCliData = false;
          if (action.payload) {
            state.cliData = action.payload;
          }
        }
      )
      .addCase(getCLIData.rejected, (state) => {
        state.loadingCliData = false;
      })
      .addCase(remediateAlert.pending, (state) => {
        state.loadingRemediation = true;
      })
      .addCase(
        remediateAlert.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingRemediation = false;
          if (action.payload) {
            state.isRemediationSuccess = true;
          }
        }
      )
      .addCase(remediateAlert.rejected, (state) => {
        state.loadingRemediation = false;
      });
  }
});

export const { setIsActionSelected, clearState } = remediationsSlice.actions;
export default remediationsSlice.reducer;
